// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Composants génériques
import S_App      from '../../services/S_App';
// import Version    from '../../elements/Version';
// import Credits    from '../../elements/Credits';
// import Saves      from '../../components/Saves';
import { Saves }   from 'react-mui-pwa-tools';
import { Version } from 'react-mui-pwa-tools';
import { Credits } from 'react-mui-pwa-tools';


// Composants Notes
import S_Notes      from '../../_notes/services/S_Notes';

// Composants Yaourts
import S_Categories           from '../../_yaourts/services/S_Categories';
import S_Ferments_Suggestions from '../../_yaourts/services/S_Ferments_Suggestions';
import S_Datas                from '../../_yaourts/services/S_Datas';
import S_Recipes              from '../../_yaourts/services/S_Recipes';
import ListLaits              from '../../_yaourts/components/ListLaits';
import ListFermentsTypes      from '../../_yaourts/components/ListFermentsTypes';
import ListItemsTypes         from '../../_yaourts/components/ListItemsTypes';
import ListFermentsSuggestions  from '../../_yaourts/components/ListFermentsSuggestions';

import MilkType               from '../../_yaourts/elements/MilkType';
import FermentType            from '../../_yaourts/elements/FermentType';

import pkg        from '../../../package.json';

// Styles
// import './PanelConfig.scss';

type PanelConfigProps = {

}

function PanelConfig(props:PanelConfigProps) {

  // props
  let {} = props;

  // state
  const [laits, setLaits] = useState([]);
  const [fermentsTypes, setFermentsTypes] = useState([]);
  const [itemsTypes, setItemsTypes] = useState([]);
  const [fermentsSuggestions, setFermentsSuggestions] = useState([]);

  // chargement des donnees
  useEffect(() => {
    S_Categories.fetch(() => {
      setLaits([...S_Categories.laits]);
      setFermentsTypes([...S_Categories.formats_ferments]);
      setItemsTypes([...S_Categories.formats]);
    });
    S_Ferments_Suggestions.fetch(() => {
      setFermentsSuggestions([...S_Ferments_Suggestions.ferments]);
    });
  }, []);

  // autres variables

  function removeFerment(name){
    S_Ferments_Suggestions.deleteFerment(name,() => {
      setFermentsSuggestions([...S_Ferments_Suggestions.ferments]);
      // this.openSnackbar('Suggestion de ferment supprimée');
    });
  }

  function createNewFerment(name){
    S_Ferments_Suggestions.newFerment(name,() => {
      setFermentsSuggestions([...S_Ferments_Suggestions.ferments]);
      // this.openSnackbar('Suggestion de ferment ajouté');
    });
  }

  function updateFerment(ferment,value){
    S_Ferments_Suggestions.updateFerment(value,ferment,() => {
      setFermentsSuggestions([...S_Ferments_Suggestions.ferments]);
      // this.openSnackbar('Suggestion de ferment modifiée');
    });
  }

  return (
    <Container fixed className="panel-contents">
      <ListLaits laits={laits}/>
      <ListFermentsTypes fermentTypes={fermentsTypes}/>
      <ListItemsTypes itemsTypes={itemsTypes}/>
      <ListFermentsSuggestions 
        fermentSuggestions={fermentsSuggestions} 
        onClickRemove={removeFerment} 
        onClickAdd={createNewFerment}
        onUpdate={updateFerment} />

      { process.env.REACT_APP_ENV === 'production' ? null : (
      <div>
        <h2>TESTS</h2>
        <div>
          <MilkType baseLait={laits[0]} />
          <FermentType type={fermentsTypes[0]} displayType={true}/>
        </div>
        <div>
          <MilkType baseLait={laits[0]} size="small" name={'Carrefour bio'} />
          <FermentType  type={fermentsTypes[0]}  size="small"  displayType={true}  name={'Activia (3x)'} />
        </div>
      </div>
      )}

      <hr/>

      
      <Saves 
        storageKeyPrefix={S_App.STORAGE_KEY_PREFIX}
        storageKeys={[S_Notes.STORAGE_KEY_NOTES,S_Datas.STORAGE_KEY_DATAS,S_Datas.STORAGE_KEY_COMPTEUR,S_Ferments_Suggestions.STORAGE_KEY_FERMENTS,S_Recipes.STORAGE_KEY_RECIPES]}
        shareTitle="Dashboard yaourts"
        className="" />

      <Version 
        development={!!process.env && process.env.NODE_ENV === 'development'}
        version={pkg.version}
        lastUpdate={pkg.lastUpdate}/>
      <Credits>
        Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
      </Credits>
    </Container>
  );
}

export default PanelConfig;
