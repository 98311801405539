import S_App    from   '../../services/S_App';

const MODEL = [
  {
    id: 0,
    name: 'Yaourts',
    subtitle: 'natures',
    quantity: '10 pots',
    ingredients: [
      'lait entier 1L',
      'ferment'
    ],
    process: [
      {
        description: 'Préchauffage du lait',
        program: 'dessert',
        duration: '25min'
      },
      {
        description: 'Refroidissement < 50°',
        duration: '30min'
      },
      {
        description: 'Ajout du ferment'
      },
      {
        description: 'Cuisson',
        program: 'yaourt',
        duration: '8h'
      }
    ],
    notes: 'mélanger plutot avec un fouet en silicone'
  },
  {
    id: 1,
    name: 'Crèmes aux oeufs',
    subtitle: 'vanille',
    quantity: '8 petits pots',
    ingredients: [
      'lait 1/2 écrémé 400ml',
      'crème entière fluide 200ml',
      'sucre 100g',
      '2 oeufs',
      'poudre vanille'
    ],
    process: [
      {
        description: 'Préchauffage lait + crème + vanille',
        program: 'dessert',
        duration: '35min'
      },
      {
        description: 'Refroidissement',
        duration: '20min'
      },
      {
        description: 'Blanchiement oeufs + sucre',
        duration: '5min'
      },
      {
        description: 'Mélange',
      },
      {
        description: 'Cuisson',
        program: 'dessert',
        duration: '35min'
      },
      {
        description: 'Frigo',
        duration: '12h mini'
      }
    ],
    notes: 'essai avec 30g stevia => goût bof, n\'a pa prit'
  },
  {
    id: 2,
    name: 'Mousses au chocolat',
    subtitle: '',
    quantity: '5 petits pots',
    ingredients: [
      '150g chocolat (1 tablette)',
      '1cs Van Houten',
      'tranche de beurre salé',
      '4 oeufs'
    ],
    process: [
      {
        description: 'Fondre chocolat',
        program: 'micro-onde',
        duration: '2 ou 3x 1min à 500w'
      },
      {
        description: 'Ajout du Van Houten et du beurre, refroidissement',
        duration: '5min'
      },
      {
        description: 'Séparation des oeufs, blancs en neige',
        duration: '3min'
      },
      {
        description: 'Ajout des jaunes au chocolat refroidi, puis incorporation des blancs',
        duration: '5min'
      },
      {
        description: 'Frigo',
        duration: '4h mini'
      }
    ],
    notes: ''
  }
];

let S_Recipes = {
  STORAGE_KEY_RECIPES : 'recipes',

  recipes: [],
  

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Recipes.tsx -- fetch()...');
    var recipes = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Recipes.STORAGE_KEY_RECIPES];
    if(recipes){
      S_Recipes.recipes = JSON.parse(recipes);
    }else{
      S_Recipes.recipes = MODEL;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Recipes.tsx -- fetch() recipes',S_Recipes.recipes);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Recipes.STORAGE_KEY_RECIPES] = JSON.stringify(S_Recipes.recipes);
    if(S_App.isLogDebug) console.log('-- S_Recipes.tsx -- store() recipes',S_Recipes.recipes);  
    if(cb)
      cb();
  },

  createNew: (cb) => {
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      name: '',
      subtitle: '',
      quantity: '',
      ingredients: [],
      process: [],
      notes: ''
    };
    S_Recipes.recipes.push(newOne);
    S_Recipes.store(() => {
      if(cb) cb(newOne);
    });
  },
  add: (data,cb) => {
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      name: data.name,
      subtitle: data.subtitle,
      quantity: data.quantity,
      ingredients: data.ingredients,
      process: data.process,
      notes: data.notes
    };
    S_Recipes.recipes.push(newOne);
    S_Recipes.store(() => {
      if(cb) cb(newOne);
    });
  },
  update(value,field,data,cb){
    if(!S_Recipes.recipes || S_Recipes.recipes.length===0){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Recipes.recipes.length; i++){
      if(S_Recipes.recipes[i].id === data.id){
        foundData = S_Recipes.recipes[i];
        foundData[field] = value;
        break;
      }
    }
    S_Recipes.store(() => {
      if(cb) cb(foundData);
    });
  },
  delete(data,cb){
    if(!S_Recipes.recipes || S_Recipes.recipes.length===0 || !data){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Recipes.recipes.length; i++){
      if(S_Recipes.recipes[i].id === data.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Recipes.recipes.splice(foundDataIndex,1);
    }
    S_Recipes.store(cb);
    if(cb) cb();
  },

};
export default S_Recipes;