import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

// Composants génériques

// Composants Yaourts

// Styles
import './TasteType.scss';

type TasteTypeProps = {
  taste: string;
}

function TasteType(props:TasteTypeProps) {

  // props
  let {taste} = props;

  if(!taste)
    return null;

  return (
    <i className="tastetype">
      <BubbleChartIcon />
      <span>{taste}</span>
      <BubbleChartIcon />
    </i>
  );
}

export default TasteType;
