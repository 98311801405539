// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';

// Composants génériques
import S_App  from '../../services/S_App';

// Composants Yaourts
import S_Categories     from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import ListCountsByYear    from   '../components/ListCountsByYear';
import ListItemsArchived   from   '../components/ListItemsArchived';

// Styles
import './PanelArchives.scss';

type PanelArchivesProps = {
  datas: I_Data[];
  onClickEdit: any;
}

function PanelArchives(props:PanelArchivesProps) {

  // props
  let {datas,onClickEdit} = props;

  // state

  // autres variables

  return (
    <Container fixed className="panel-contents">

      <div className="mv-2">
        <ListCountsByYear datas={datas} />
      </div>
      
      <div className="archives mv-2">
        <ListItemsArchived datas={datas} onClickEdit={onClickEdit} />
      </div>

    </Container>
  );
}

export default PanelArchives;
