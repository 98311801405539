// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';

// Composants génériques

// Composants Yaourts
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import Dashboard   from '../components/Dashboard';

// Styles
import './PanelDashboard.scss';

type PanelDashboardProps = {
  datas: I_Data[];
  onClickEditData: any;  //func
  onClickCreateData: any;  //func

  onMoveDataToFermentation: any;
  onMoveDataToEgouttage: any;
  onMoveDataToFrigo: any;
  onMoveDataToCongel: any;
  onMoveDataToDecongel: any;
  onMoveDataToPlacard: any;

  onDragStart: any;
  onDragEnd: any;
}

function PanelDashboard(props:PanelDashboardProps) {

  // props
  let {
    datas,
    onClickEditData,onClickCreateData,
    onMoveDataToFermentation,onMoveDataToEgouttage,onMoveDataToFrigo,onMoveDataToCongel,onMoveDataToDecongel,onMoveDataToPlacard,
    onDragStart,onDragEnd
  } = props;

  // state

  // autres variables



  

  // ouvre le modal d'edition
  function openEdition(e,data) {
    e.preventDefault();
    e.stopPropagation();
    // console.log('Edit data',data);

    onClickEditData(data);
  }


  let archived = [];
  let decongel = [];
  let frigo = [];
  let egouttage = [];
  let fermentation = [];
  let congel = [];
  let placard = [];
  for(var i = 0; i < datas.length; i++){
    let data = datas[i];
    let status = S_Datas.getStatus(data);
    if(status === S_Datas.STATUS_ARCHIVED)
      archived.push(data);
    if(status === S_Datas.STATUS_CONGEL)
      congel.push(data);
    if(status === S_Datas.STATUS_FRIGO)
      frigo.push(data);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttage.push(data);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentation.push(data);
    if(status === S_Datas.STATUS_DECONGEL)
      decongel.push(data);
    if(status === S_Datas.STATUS_PLACARD)
      placard.push(data);
  }

  return (
    <Container fixed className="panel-contents">
      <Dashboard
        fermentation={fermentation}
        egouttage={egouttage}
        frigo={frigo}
        congel={congel}
        decongel={decongel}
        placard={placard}

        onClickCreate={onClickCreateData}
        onClickEdit={openEdition}

        onMoveDataToFermentation={onMoveDataToFermentation}
        onMoveDataToEgouttage={onMoveDataToEgouttage}
        onMoveDataToFrigo={onMoveDataToFrigo}
        onMoveDataToCongel={onMoveDataToCongel}
        onMoveDataToDecongel={onMoveDataToDecongel}
        onMoveDataToPlacard={onMoveDataToPlacard}

        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        />
    </Container>
  );
}

export default PanelDashboard;
