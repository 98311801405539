import S_App    from   './S_App';

const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

let S_Date = {
  isLogDebug: false, //S_App.isLogDebug,  


  sortByDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.date !== undefined ? a.date : a.id;
      let orderB = b.date !== undefined ? b.date : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  sortByFermentationDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.fermentationDate !== undefined ? a.fermentationDate : a.id;
      let orderB = b.fermentationDate !== undefined ? b.fermentationDate : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  

  getLastDate(data) {
    let lastDate = undefined;
    try{
      if(data.fermentationDate)
        lastDate = new Date(data.fermentationDate);
      if(data.egouttageDate)
        lastDate = new Date(data.egouttageDate);
      if(data.frigoDate)
        lastDate = new Date(data.frigoDate);
      if(data.congelDate)
        lastDate = new Date(data.congelDate);
      if(data.decongelDate)
        lastDate = new Date(data.decongelDate);
    }catch(err){
      console.error('Error in getLastDate',err);
    }
    return lastDate;
  },
  groupByFermentationYear(array) {
    return array.reduce((hash, obj) => {
      if(obj===undefined || obj.fermentationDate === undefined) return hash;
      let fermentationYear = (new Date(obj.fermentationDate)).toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }); 
      return Object.assign(hash, { [fermentationYear]:( hash[fermentationYear] || [] ).concat(obj)})
    }, {})
  },
  groupByLastDateYear(array) {
    return array.reduce((hash, obj) => {
      if(obj===undefined) return hash;
      let lastDate = S_Date.getLastDate(obj);
      if(lastDate===undefined) return hash;
      let lastDateYear = lastDate.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }); 
      return Object.assign(hash, { [lastDateYear]:( hash[lastDateYear] || [] ).concat(obj)})
    }, {})
  },

  formatDateForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  },
  formatTimeForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let hour = theDate.getHours();
      let minute = theDate.getMinutes();
      date = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;
    }
    return date;
  },

  formatDateInString(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatDateInStringWithName(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' });
    return null;
  },
  formatDateInStringWithNameNoYear(dateInMillis:number):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatHourInString(dateInMillis:number):string{
    if(dateInMillis){
      let min = (new Date(dateInMillis)).getMinutes();
      return (new Date(dateInMillis)).getHours() + 'h' + (min >=10 ? min : '0'+min);
    }
    return null;
  },

  formatDelayForNow(dateInMillis:number, reverse:boolean):number{
    if(!dateInMillis)
      return null;
    let ecart = dateInMillis - (new Date()).getTime();
    if(reverse)
      ecart = (new Date()).getTime() - dateInMillis;
    let days = ecart / 1000 / 60 / 60 / 24;
    return Math.round(days);
  },
  formatDateForMillis(dateInMaterial){
    if(!dateInMaterial)
      return undefined;
    // let year = parseInt(dateInMaterial.substr(0,4));
    // let month = parseInt(dateInMaterial.substr(6,8));
    // let day = parseInt(dateInMaterial.substr(10,12));
    return new Date(dateInMaterial);
  },

  formatDelayFrom(days:number):string{
    if(days===undefined)
      return;
    if(days>365){
      let nombreDAn = Math.round(days / 365);
      return 'Depuis + de '+nombreDAn+' ans';
    }
    if(days>30){
      let nombreDeMois = Math.round(days / 30);
      return 'Depuis + de '+nombreDeMois+' mois';
    }
    let result = '';
    if(days<=30){
      result = 'Depuis '+days+' jours';
    }
    if(days===1){
      result = 'Depuis hier';
    }
    if(days===0){
      result = "Depuis aujourd'hui";
    }
    if(days<0){
      result = "Incohérent";
    }
    return result;
  },
  formatDelayAgo(days:number):string{
    if(days===undefined)
      return;
    if(days>365){
      let nombreDAn = Math.round(days / 365);
      return 'Il y a + de '+nombreDAn+' ans';
    }
    if(days>30){
      let nombreDeMois = Math.round(days / 30);
      return 'Il y a + de '+nombreDeMois+' mois';
    }
    let result = '';
    if(days<=30){
      result = 'Il y a '+days+' jours';
    }
    if(days===1){
      result = 'Hier';
    }
    if(days===0){
      result = "Aujourd'hui";
    }
    if(days<0){
      result = "Incohérent";
    }
    return result;
  },

  daysBetween(dateInMillis1,dateInMillis2):number {
    if(dateInMillis2===undefined || dateInMillis1===undefined)
      return null;
    let gapInMillis = dateInMillis2 - dateInMillis1;
    return Math.round(gapInMillis / 1000 / 60 / 60 / 24);
  },
  hoursBetween(dateInMillis1,dateInMillis2):number {
    if(dateInMillis2===undefined || dateInMillis1===undefined)
      return null;
    let gapInMillis = dateInMillis2 - dateInMillis1;
    return Math.round(gapInMillis / 1000 / 60 / 60);
  },

  formatDateInMillis(dateInMaterial){
    if(!dateInMaterial)
      return undefined;
    return (new Date(dateInMaterial)).getTime();
  },
  formatDateInMillis2(dayInMaterial,timeInMaterial){
    if(!dayInMaterial)
      return undefined;

    if(S_Date.isLogDebug) console.log('formatDateInMillis2() ',dayInMaterial,timeInMaterial);
    let hours = 1;
    let minutes = 0;
    if(!!timeInMaterial){
      hours = timeInMaterial.split(':')[0];
      minutes = timeInMaterial.split(':')[1];
    }

    let dateDate = new Date(dayInMaterial);
    dateDate = new Date(dateDate.setHours(hours,minutes,0,0));
    if(S_Date.isLogDebug) console.log('formatDateInMillis2() ',dateDate);
    let dateMillis = dateDate.getTime();

    return dateMillis;
  },

  getCurrentDayReference(){
    let currentDayDate = new Date();
    currentDayDate = new Date(currentDayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let currentDayMillis = currentDayDate.getTime();
    // if(S_Date.isLogDebug) console.log('getCurrentDayReference',currentDayMillis);
    return currentDayMillis;
  },
  getDayReference(dateInMillis:number):number{
    let dayDate = new Date(dateInMillis);
    dayDate = new Date(dayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let dayMillis = dayDate.getTime();
    // if(S_Date.isLogDebug) console.log('getDayReference',dayMillis);
    return dayMillis;
  },
  getDayReferencesOfMonth(year:number,month:number){
    var date = new Date(year, month, 1);
    var daysInMillis = [];
    while (date.getMonth() === month) {
      let day = new Date(date);
      daysInMillis.push(S_Date.getDayReference(day.getTime()));
      date.setDate(date.getDate() + 1);
    }
    return daysInMillis;
  },
  getDayReferencesBetween(dateInMillis1:number,dateInMillis2:number){
    var date = new Date(dateInMillis1);
    if(S_Date.isLogDebug) console.log('-- S_Date -- getDayReferencesBetween()',date,new Date(dateInMillis2));
    var daysInMillis = [];
    while (date.getTime() <= dateInMillis2) {
      let day = new Date(date);
      if(S_Date.isLogDebug) console.log('-- S_Date -- getDayReferencesBetween()',day);
      daysInMillis.push(S_Date.getDayReference(day.getTime()));
      date = new Date(day.getTime() + ONE_DAY_IN_MILLIS);
    }
    return daysInMillis;
  }
};
export default S_Date;