import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

// Composants génériques
import S_App         from '../../services/S_App';

// Composants Yaourts
import S_Datas         from '../services/S_Datas';
import S_Categories    from '../services/S_Categories';
import {I_Data}        from '../services/I_Data';

// Styles
// import './Appreciation.scss';

type DialogMoveToFrigoProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;

  data: I_Data;  
}

function DialogMoveToFrigo(props:DialogMoveToFrigoProps) {

  // props
  let {isDialogOpened,closeDialog,data,onClickValidate} = props;

  let defaultCategory_formatID = data && !!data.category_formatID ? data.category_formatID : 20;

  // state
  const [fermentationTime, setFermentationTime] = useState(data.fermentationTimeInHours);
  const [egouttageTime, setEgouttageTime] = useState(undefined);
  const [isFerment, setIsFerment] = useState(false);
  const [category_formatID, setCategory_formatID] = useState(defaultCategory_formatID);
  const [taste, setTaste] = useState('');
  const [newQuantity, setNewQuantity] = useState(1);    // quantite a creer
  const [quantity, setQuantity] = useState(1);      // quantite a deplacer
  const [quantityPetitsLaits, setQuantityPetitsLaits] = useState(0);
  const [tag, setTag] = useState(undefined);
  

  function changeFermentationTime(value){
    setFermentationTime(value);
  }
  function changeEgouttageTime(value){
    setEgouttageTime(value);
  }
  function changeIsFerment(value){
    setIsFerment(value);
  }
  function changeCategoryFormatID(value){
    setCategory_formatID(value);
  }
  function changeTaste(value){
    setTaste(value);
  }
  function changeNewQuantity(value){
    setNewQuantity(value);
  }
  function changeQuantity(value){
    setQuantity(value);
  }
  function changeQuantityPetitsLaits(value){
    setQuantityPetitsLaits(value);
  }
  function changeTag(value){
    setTag(value);
  }

  let formatsArray = S_App.sortByOrder(S_Categories.formats);
  let status = S_Datas.getStatus(data);
  let dataType = S_Categories.getFormat(category_formatID);
  let tagsArray = dataType && dataType.tags ? dataType.tags : [];

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Mettre au frigo</DialogTitle>
      <DialogContent>

        {S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION ? (
        <TextField label="Durée de fermentation (en heures)" type="number" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={fermentationTime} 
          onChange={(e) => changeFermentationTime(e.target.value)}/>
        ) : null}
        {S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE ? (
        <TextField label="Durée d'égouttage' (en heures)" type="number" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={egouttageTime} 
          onChange={(e) => changeEgouttageTime(e.target.value)}/>
        ) : null}

        {false ? (
        <FormControlLabel
          control={
            <Switch
              checked={isFerment}
              onChange={(e) => changeIsFerment(e.target.checked)}
              name="isFerment"/>
          }
          label="C'est un ferment" />
        ) : null}

        <FormControl fullWidth size="small">
          <InputLabel id="label-type">Type de préparation</InputLabel>
          <Select autoFocus labelId="label-format" labelWidth={80}  name="format" 
            value={category_formatID}
            onChange={(e) => changeCategoryFormatID(e.target.value)}>
            { formatsArray.map( (type,index) => <MenuItem value={type.id} key={index}>{ type.name }{ !!type.to_be_replaced_by ? ' (old)' : ''}</MenuItem> ) }
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth>
          <InputLabel id="label-format">Tag</InputLabel>
          <Select labelId="label-format" labelWidth={80} 
            value={tag}
            onChange={(e) => changeTag(e.target.value)}>
            <MenuItem value={undefined}></MenuItem>
            { tagsArray.map( (otag,index) => <MenuItem value={otag} key={index}>{ otag }</MenuItem> ) }
          </Select>
        </FormControl>

        <TextField label="Saveur" size="small" fullWidth InputLabelProps={{shrink:true}} 
          value={taste} 
          onChange={(e) => changeTaste(e.target.value)}/>
        <TextField label="Nombre de pots" type="number" size="small" fullWidth InputLabelProps={{shrink:true}} 
          value={newQuantity} 
          onChange={(e) => changeNewQuantity(e.target.value)}/>

        {!S_Datas.isSingle(data) ? (
        <>
        <br/>
        <TextField id="quantity" label={'Quantité à utiliser (max '+data.quantity+')'} type="number" InputProps={{inputProps: { max: data.quantity, min: 1 }}} fullWidth size="small"
          value={quantity}
          onChange={(e) => changeQuantity(e.target.value)} />
        </>
        ):null}

        {status===S_Datas.STATUS_EGOUTTAGE ? (
        <>
        <br/>
        <br/>
        <h3>Est-ce qu'on met du petit lait ?</h3>
        <TextField id="quantityPetitLait" label={'Quantité de petits laits'} type="number" fullWidth size="small"
          value={quantityPetitsLaits}
          onChange={(e) => changeQuantityPetitsLaits(e.target.value)} />
        </>
        ):null}

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(fermentationTime,egouttageTime,isFerment,category_formatID,tag,taste,newQuantity,quantity,quantityPetitsLaits)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogMoveToFrigo;
