import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import AcUnitIcon from '@material-ui/icons/AcUnit';

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import FermentType  from   '../elements/FermentType';

// Styles
import './DataFerment.scss';

type DataFermentProps = {
  data: I_Data;
  asA?: string;  // ingredient / preparation
  onClickEdit?: any;  // func
  onDragStart?: any;  // func
}

function DataFerment(props:DataFermentProps) {

  // props
  let {data,asA,onClickEdit,onDragStart} = props;

  // state

  // autres variables

  if(!data)
    return null;

  if(asA === 'preparation'){
    let limitDate = S_Datas.getLimitDate(data);
    let isInCongel = S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL;
    let isExpired = S_Datas.isExpired(data);
    let fermentationIncrementPreparation = data.fermentationIncrement + 1;  // data.fermentationIncrement c'est celui de l'ingredient

    let limitDateFormatted = '';
    if(limitDate){
      limitDateFormatted = limitDate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined });
      let todayAt0 = (new Date()).getTime();
      if(limitDate > todayAt0 && limitDate <= (todayAt0 + 24*60*60*1000)){
        limitDateFormatted = 'demain';
      }
      if(limitDate > todayAt0 && limitDate <= (todayAt0 + 24*60*60*1000*2)){
        limitDateFormatted = 'après-demain';
      }
      if(isInCongel){
        limitDateFormatted = limitDate ? limitDate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: undefined, weekday: undefined }) : null;
      }
    }

    let fermentName = data.baseName;
    if(!(data.isFerment && data.isOriginFerment) && fermentationIncrementPreparation>0){
      fermentName += ' ('+fermentationIncrementPreparation+'x)';
    }


    return (
      <Paper className={'ferment asAFerment' + (isExpired ? ' expired' : '') + (!!onClickEdit || !!onDragStart ? ' clickable' : '')}
        elevation={5}
        onClick={onClickEdit}
        draggable 
        onDragStart={onDragStart}>
        <FermentType 
          type={S_Categories.getFormatFerment(data.category_formatID)} 
          displayType={true}
          name={fermentName} />
        { limitDateFormatted ? <div className="dluo"><small>DLUO</small> <span>{limitDateFormatted}</span></div> : null }
        { data && data.quantity>1 ? <div className="quantity">{data.quantity}</div> : null }
        { data.congelDate ? <div className="freeze"><AcUnitIcon /></div> : null }
        
        <div className="id">ID {data.id} ({data.idExtractedFrom}) : {data.fermentParentID}</div>
      </Paper>
    );
  }

  // ingredient
  let name = data.baseName;
  if(data.fermentationIncrement>0){
    name += ' ' +'('+data.fermentationIncrement+'x)';
  }
  return (
    <FermentType 
      type={S_Categories.getFormatFerment(data.fermentTypeID)} 
      size="small" 
      displayType={true} 
      name={name} />
  );
}

export default DataFerment;
