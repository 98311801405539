// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';

// Composants génériques

// Composants Yaourts

// Styles
// import './ListLaits.scss';

type ListItemsTypesProps = {
  itemsTypes: any[];
}

function ListItemsTypes(props:ListItemsTypesProps) {

  // props
  let {itemsTypes} = props;

  // state

  // autres variables


  return (
    <>
    <h2>Types de préparations (non configurables)</h2>
    <Grid className="list-items-types" container spacing={2} justify="center">
      {itemsTypes.map((type,index) => {
        if(!!type.to_be_replaced_by)
          return;
        return (
          <Grid item xs={4} key={index} className="text-center">
            <div className={'type small ' + type.cssClass}>{type.name}</div>
          </Grid>
        );
      })}
    </Grid>
    </>
  );
}

export default ListItemsTypes;
