import S_App    from   '../../services/S_App';
import {I_Note} from   './I_Note';

let TEST_NOTES = [];

let S_Notes = {
  STORAGE_KEY_NOTES : 'notes',

  notes : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- fetch()...');
    var notes = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Notes.STORAGE_KEY_NOTES];
    if(notes){
      S_Notes.notes = JSON.parse(notes);
    }else{
      S_Notes.notes = TEST_NOTES;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- fetch() notes',S_Notes.notes);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Notes.STORAGE_KEY_NOTES] = JSON.stringify(S_Notes.notes);
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- store() notes',S_Notes.notes); 
    if(cb)
      cb();
  },


  getNote: (id:number|string) => {
    return S_App.getByID(S_Notes.notes,id);
  },

  addNote(cb){
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- addNote()...');
      
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      lastupdate: dateNow,
      content: ''
    };
    if(!S_Notes.notes)
      S_Notes.notes = [];
    S_Notes.notes.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- addNote() added',newOne);
    S_Notes.store(() => {
      if(cb) cb();
    });
  },
  updateNote(value,note:I_Note,cb){
    if(!S_Notes.notes || S_Notes.notes.length===0 || !note){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Notes.notes.length; i++){
      if(S_Notes.notes[i].id === note.id){
        foundData = S_Notes.notes[i];
        foundData.content = value;
        foundData.lastupdate = (new Date()).getTime();
        break;
      }
    }
    S_Notes.store(() => {
      if(cb) cb(foundData);
    });
  },
  removeNote(note:I_Note,cb){
    if(!S_Notes.notes || S_Notes.notes.length===0 || !note){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- removeNote() delete',note,'...'); 
    let foundNoteIndex = S_App.getIndexOfByID(S_Notes.notes,note.id);
    if(foundNoteIndex !== -1){
      S_Notes.notes.splice(foundNoteIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Notes.tsx -- removeNote() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Notes.tsx -- removeNote() not found');
    }
    S_Notes.store(() => {
      if(cb) cb();
    });
  }

};
export default S_Notes;