export const C_ITEMS_TYPES = [
  {
    'id':1,
    'name':'Petit lait',
    'cssClass':'color-petit_lait'
  },
  {
    'id':2,
    'name':'Sachet de poudre',
    'cssClass':'color-sachet'
  },
  {
    'id':20,
    'name':'Yaourt',
    'cssClass':'color-yaourt',
    'tags': [
      'Yaourt nature',
      'Yaourt crémeux',
      'Yaourt brassé',
      'Yaourt égoutté',
      'Yaourt infusé',
      'Yab',
      'Skyr',
      'Petit suisse',
      'Fromage blanc',
      'Yaourt des neiges'
    ]
  },
  {
    'id':30,
    'name':'Fromage',
    'cssClass':'color-fromage'
  },
  {
    'id':40,
    'name':'Dessert',
    'cssClass':'color-dessert',
    'tags': [
      'Crèmes aux oeufs',
      'Crèmes choco',
      'Mousses choco',
      'Panacottas',
      'Cheesecakes',
      'Riz au lait',
      'Flans'
    ]
  },
  {
    'id':50,
    'name':'Autre',
    'cssClass':'color-autre'
  }
];