// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotesIcon from '@material-ui/icons/Notes';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Composants génériques
import S_App  from '../../services/S_App';
import S_Date from '../../services/S_Date';

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import PreparationCardArchive   from   '../elements/PreparationCardArchive';
import MilkType     from   '../elements/MilkType';
import Appreciation from   '../elements/Appreciation';
// import DataFerment  from   '../elements/DataFerment';
import FermentType  from   '../elements/FermentType';

// Styles
// import './ListItems.scss';

type ListItemsArchivedProps = {
  datas: I_Data[];
  onClickEdit?: any;
}

function ListItemsArchived(props:ListItemsArchivedProps) {

  // props
  let {datas,onClickEdit} = props;

  // state
  const [popArray, setPopArray] = useState([]);

  // autres variables

  function togglePop(eTarget,data:I_Data){
    if(!data)
      return;
    // console.log('Click target:',eTarget);
    if(!popArray[data.id]){
      popArray[data.id] = eTarget;
    }else{
      popArray[data.id] = undefined;
    }
    setPopArray([...popArray]);
  }

  let datasSorted = S_Datas.sortByLastDate(datas).reverse();
  // console.log('datasSorted',datasSorted);
  let datasSortedGroupByYear = S_Date.groupByLastDateYear(datasSorted);
  console.log('datasSortedGroupByDate',datasSortedGroupByYear);
  let years = Object.keys(datasSortedGroupByYear).reverse();

  return (
    <>
      {years.map((year,indexYear) => {
        let datasSortedForYear = datasSortedGroupByYear[year];
        return (
          <div key={indexYear}>
            <h3>{year}</h3>

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="center">Ingrédients</TableCell>
                    <TableCell align="right">Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datasSortedForYear.map((data, index) => {
                    // car utilise aussi sur le modal edit pour voir les stats d'un bain
                    // if(!data.archived)
                    //   return null;

                    let status = S_Datas.getStatus(data);
                    let limitDate = S_Datas.getLimitDate(data);
                    let limitDateFormatted = undefined;
                    if(status === S_Datas.STATUS_FRIGO || status === S_Datas.STATUS_DECONGEL)
                      limitDateFormatted = limitDate ? limitDate.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: 'short' }) : null;
                    if(status === S_Datas.STATUS_CONGEL)
                      limitDateFormatted = limitDate ? limitDate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: undefined, weekday: undefined }) : null;
                    let lastDate = S_Datas.getLastDate(data);
                    let lastDateFormattedYear = lastDate ? lastDate.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }) : null;
                    let lastDateFormatted = lastDate ? lastDate.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined }) : null;
                    
                    let fermentName = data.baseName;
                    if(data.fermentationIncrement>0){
                      fermentName += ' ' +'('+data.fermentationIncrement+'x)';
                    }

                    return (
                    <TableRow key={data.id} className={(data.archived ? 'archived' : 'available')}>
                      <TableCell align="center">
                        { lastDateFormatted || lastDateFormattedYear ? (
                        <div className="dateCell" onClick={(e) => onClickEdit(data)}>
                          <span className="lastDate"><span className="day">{lastDateFormatted}</span></span>
                        </div>
                        ) : null}
                      </TableCell>
                      <TableCell align="left">
                        <PreparationCardArchive
                          data={data}
                          onClick={(e) => onClickEdit(data)} />
                      </TableCell> 
                      <TableCell align="center">
                        <div onClick={(e) => onClickEdit(data)} className="nowrap">
                          <MilkType 
                            baseLait={S_Categories.getLait(data.baseLaitID)}
                            size="small"
                            name={data.baseLaitName}   />
                          <FermentType 
                            type={S_Categories.getFormatFerment(data.fermentTypeID)} 
                            size="small" 
                            displayType={true} 
                            name={fermentName} />
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        { data.notes ? [
                          <div key={0} aria-describedby={'pop-'+data.id} onClick={(e) => togglePop(e.currentTarget,data)}>
                            <Appreciation data={data} />
                            <div className="notes"><NotesIcon /></div>
                          </div>,
                          <Popover key={1} id={'pop-'+data.id}
                            open={popArray[data.id]!==undefined}
                            onClose={(e) => togglePop(undefined,data)}
                            anchorEl={popArray[data.id]}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}>
                            <Card>
                              <CardContent>
                                <div dangerouslySetInnerHTML={{__html: S_App.replaceBr(data.notes)}}></div>
                              </CardContent>
                            </Card>
                          </Popover>
                        ] : (
                          <Appreciation data={data} />
                        ) }
                      </TableCell>
                    </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </>
  );
}

export default ListItemsArchived;
