// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App  from '../../services/S_App';
import S_Date from '../../services/S_Date';

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import ListItems        from   '../components/ListItems';
import ListItemsArchived   from   '../components/ListItemsArchived';

// Styles
// import './Tree.scss';

type TreeProps = {
  data: I_Data;
}

function Tree(props:TreeProps) {

  // props
  let {data} = props;

  // state

  // autres variables
  let datasOfBain_same = S_Datas.sortByLastDate(S_Datas.getDatasOfBain_same(data.idExtractedFrom,data.id)); 
  let datasOfBain_sameParent = S_Datas.sortByLastDate(S_Datas.getDatasOfBain_sameParent(data.fermentParentID,data.id)); 
  let datasOfBain_childs = S_Datas.sortByLastDate(S_Datas.getDatasOfBain_childs(data.idExtractedFrom)); 
  let datasOfBain_parent = S_Datas.sortByLastDate(S_Datas.getDatasOfBain_parent(data.fermentParentID)); 
  
  let datasOfBainNotArchived_same = S_App.getByFilters(datasOfBain_same,[{attribute:'archived',value:false}]);
  let datasOfBainNotArchived_sameParent = S_App.getByFilters(datasOfBain_sameParent,[{attribute:'archived',value:false}]);
  let datasOfBainNotArchived_childs = S_App.getByFilters(datasOfBain_childs,[{attribute:'archived',value:false}]);
  let datasOfBainNotArchived_parent = S_App.getByFilters(datasOfBain_parent,[{attribute:'archived',value:false}]);
  let datasOfBainArchived_same = S_App.getByFilters(datasOfBain_same,[{attribute:'archived',value:true}]);
  let datasOfBainArchived_sameParent = S_App.getByFilters(datasOfBain_sameParent,[{attribute:'archived',value:true}]);
  let datasOfBainArchived_childs = S_App.getByFilters(datasOfBain_childs,[{attribute:'archived',value:true}]);
  let datasOfBainArchived_parent = S_App.getByFilters(datasOfBain_parent,[{attribute:'archived',value:true}]);
  
  

  let decongelOfBainNotArchived_same = [];
  let frigoOfBainNotArchived_same = [];
  let egouttageOfBainNotArchived_same = [];
  let fermentationOfBainNotArchived_same = [];
  let congelOfBainNotArchived_same = [];
  let placardOfBainNotArchived_same = [];
  for(var i = 0; i < datasOfBainNotArchived_same.length; i++){
    let dataOfBain = datasOfBainNotArchived_same[i];
    let status = S_Datas.getStatus(dataOfBain);
    if(status === S_Datas.STATUS_CONGEL)
      congelOfBainNotArchived_same.push(dataOfBain);
    if(status === S_Datas.STATUS_FRIGO)
      frigoOfBainNotArchived_same.push(dataOfBain);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttageOfBainNotArchived_same.push(dataOfBain);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentationOfBainNotArchived_same.push(dataOfBain);
    if(status === S_Datas.STATUS_DECONGEL)
      decongelOfBainNotArchived_same.push(dataOfBain);
    if(status === S_Datas.STATUS_PLACARD)
      placardOfBainNotArchived_same.push(dataOfBain);
  }
  console.log('ModalDataEdition - congelOfBainNotArchived_same',congelOfBainNotArchived_same);

  let decongelOfBainNotArchived_sameParent = [];
  let frigoOfBainNotArchived_sameParent = [];
  let egouttageOfBainNotArchived_sameParent = [];
  let fermentationOfBainNotArchived_sameParent = [];
  let congelOfBainNotArchived_sameParent = [];
  let placardOfBainNotArchived_sameParent = [];
  for(var i = 0; i < datasOfBainNotArchived_sameParent.length; i++){
    let dataOfBain = datasOfBainNotArchived_sameParent[i];
    let status = S_Datas.getStatus(dataOfBain);
    if(status === S_Datas.STATUS_CONGEL)
      congelOfBainNotArchived_sameParent.push(dataOfBain);
    if(status === S_Datas.STATUS_FRIGO)
      frigoOfBainNotArchived_sameParent.push(dataOfBain);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttageOfBainNotArchived_sameParent.push(dataOfBain);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentationOfBainNotArchived_sameParent.push(dataOfBain);
    if(status === S_Datas.STATUS_DECONGEL)
      decongelOfBainNotArchived_sameParent.push(dataOfBain);
    if(status === S_Datas.STATUS_PLACARD)
      placardOfBainNotArchived_sameParent.push(dataOfBain);
  }
  
  let decongelOfBainNotArchived_childs = [];
  let frigoOfBainNotArchived_childs = [];
  let egouttageOfBainNotArchived_childs = [];
  let fermentationOfBainNotArchived_childs = [];
  let congelOfBainNotArchived_childs = [];
  let placardOfBainNotArchived_childs = [];
  for(var i = 0; i < datasOfBainNotArchived_childs.length; i++){
    let dataOfBain = datasOfBainNotArchived_childs[i];
    let status = S_Datas.getStatus(dataOfBain);
    if(status === S_Datas.STATUS_CONGEL)
      congelOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_FRIGO)
      frigoOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttageOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentationOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_DECONGEL)
      decongelOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_PLACARD)
      placardOfBainNotArchived_childs.push(dataOfBain);
  }

  let decongelOfBainNotArchived_parent = [];
  let frigoOfBainNotArchived_parent = [];
  let egouttageOfBainNotArchived_parent = [];
  let fermentationOfBainNotArchived_parent = [];
  let congelOfBainNotArchived_parent = [];
  let placardOfBainNotArchived_parent = [];
  for(var i = 0; i < datasOfBainNotArchived_parent.length; i++){
    let dataOfBain = datasOfBainNotArchived_parent[i];
    let status = S_Datas.getStatus(dataOfBain);
    if(status === S_Datas.STATUS_CONGEL)
      congelOfBainNotArchived_parent.push(dataOfBain);
    if(status === S_Datas.STATUS_FRIGO)
      frigoOfBainNotArchived_parent.push(dataOfBain);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttageOfBainNotArchived_parent.push(dataOfBain);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentationOfBainNotArchived_parent.push(dataOfBain);
    if(status === S_Datas.STATUS_DECONGEL)
      decongelOfBainNotArchived_parent.push(dataOfBain);
    if(status === S_Datas.STATUS_PLACARD)
      placardOfBainNotArchived_parent.push(dataOfBain);
  }

  return (
    <>
      {datasOfBain_parent.length>0 ? (
      <>
      <Accordion className="accordion-no-paper">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="stats-content"
          id="stats-header">
          <strong>Parent</strong>
        </AccordionSummary>
        <AccordionDetails>
          <div className="accordion-content text-center">
            {datasOfBainNotArchived_parent.length>0 ? (
            <div>
              {fermentationOfBainNotArchived_parent.length>0 ? <div><strong>En fermentation</strong><ListItems items={fermentationOfBainNotArchived_parent}/></div> : null}
              {egouttageOfBainNotArchived_parent.length>0 ? <div><strong>En égouttage</strong><ListItems items={egouttageOfBainNotArchived_parent}/></div> : null}
              {frigoOfBainNotArchived_parent.length>0 ? <div><strong>Au frigo</strong><ListItems items={frigoOfBainNotArchived_parent}/></div> : null}
              {decongelOfBainNotArchived_parent.length>0 ? <div><strong>En décongélation</strong><ListItems items={decongelOfBainNotArchived_parent}/></div> : null}
              {congelOfBainNotArchived_parent.length>0 ? <div><strong>Au congèl</strong><ListItems items={congelOfBainNotArchived_parent}/></div> : null}
              {placardOfBainNotArchived_parent.length>0 ? <div><strong>Au placard</strong><ListItems items={placardOfBainNotArchived_parent}/></div> : null}
            </div>
            ) : null}
            {datasOfBainArchived_parent.length>0 ? <ListItemsArchived datas={datasOfBainArchived_parent}/> : null}
          </div>
        </AccordionDetails>
      </Accordion>
      </>
      ):null}

      {datasOfBain_sameParent.length>0 ? (
      <>
      <Accordion className="accordion-no-paper">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="stats-content"
          id="stats-header">
          <strong>Même parent</strong>
        </AccordionSummary>
        <AccordionDetails>
          <div className="accordion-content text-center">
            {datasOfBainNotArchived_sameParent.length>0 ? (
            <div>
              {fermentationOfBainNotArchived_sameParent.length>0 ? <div><strong>En fermentation</strong><ListItems items={fermentationOfBainNotArchived_sameParent}/></div> : null}
              {egouttageOfBainNotArchived_sameParent.length>0 ? <div><strong>En égouttage</strong><ListItems items={egouttageOfBainNotArchived_sameParent}/></div> : null}
              {frigoOfBainNotArchived_sameParent.length>0 ? <div><strong>Au frigo</strong><ListItems items={frigoOfBainNotArchived_sameParent}/></div> : null}
              {decongelOfBainNotArchived_sameParent.length>0 ? <div><strong>En décongélation</strong><ListItems items={decongelOfBainNotArchived_sameParent}/></div> : null}
              {congelOfBainNotArchived_sameParent.length>0 ? <div><strong>Au congèl</strong><ListItems items={congelOfBainNotArchived_sameParent}/></div> : null}
              {placardOfBainNotArchived_sameParent.length>0 ? <div><strong>Au placard</strong><ListItems items={placardOfBainNotArchived_sameParent}/></div> : null}
            </div>
            ) : null}
            {datasOfBainArchived_sameParent.length>0 ? <ListItemsArchived datas={datasOfBainArchived_sameParent}/> : null}
          </div>
        </AccordionDetails>
      </Accordion>
      </>
      ):null}

      {datasOfBain_childs.length>0 ? (
      <>
      <Accordion className="accordion-no-paper">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="stats-content"
          id="stats-header">
          <strong>Créés avec ce bain</strong>
        </AccordionSummary>
        <AccordionDetails>
          <div className="accordion-content text-center">
            {datasOfBainNotArchived_childs.length>0 ? (
            <div>
              {fermentationOfBainNotArchived_childs.length>0 ? <div><strong>En fermentation</strong><ListItems items={fermentationOfBainNotArchived_childs}/></div> : null}
              {egouttageOfBainNotArchived_childs.length>0 ? <div><strong>En égouttage</strong><ListItems items={egouttageOfBainNotArchived_childs}/></div> : null}
              {frigoOfBainNotArchived_childs.length>0 ? <div><strong>Au frigo</strong><ListItems items={frigoOfBainNotArchived_childs}/></div> : null}
              {decongelOfBainNotArchived_childs.length>0 ? <div><strong>En décongélation</strong><ListItems items={decongelOfBainNotArchived_childs}/></div> : null}
              {congelOfBainNotArchived_childs.length>0 ? <div><strong>Au congèl</strong><ListItems items={congelOfBainNotArchived_childs}/></div> : null}
              {placardOfBainNotArchived_childs.length>0 ? <div><strong>Au placard</strong><ListItems items={placardOfBainNotArchived_childs}/></div> : null}
            </div>
            ) : null}
            {datasOfBainArchived_childs.length>0 ? <ListItemsArchived datas={datasOfBainArchived_childs}/> : null}
          </div>
        </AccordionDetails>
      </Accordion>
      </>
      ):null}
    </>
  );
}

export default Tree;
