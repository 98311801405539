import S_App    from   '../../services/S_App';
import {C_LAITS}             from   './C_Laits';
import {C_ITEMS_TYPES}       from   './C_Items_Types';
import {C_FERMENTS_FORMATS}  from   './C_Ferments_Formats';

let TEST_CATEGORY = [];

let S_Categories = {
  laits : [],        // ex : entier, demi-ecreme, ecreme, soja...
  formats : [],      // ex : petit lait, yaourt, fromage...
  formats_ferments : [],      // ex : petit lait, yaourt, fromage...

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch()...');
    S_Categories.laits = C_LAITS;
    S_Categories.formats = C_ITEMS_TYPES;
    S_Categories.formats_ferments = C_FERMENTS_FORMATS;
      
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() laits',S_Categories.laits);
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() formats',S_Categories.formats);
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() formats_ferments',S_Categories.formats_ferments);
    if(cb)
      cb();
  },
  store: (cb) => { 
    if(cb)
      cb();
  },

  getLait: (id:number|string) => {
    return S_App.getByID(S_Categories.laits,id);
  },
  getFormat: (id:number|string) => {
    return S_App.getByID(S_Categories.formats,id);
  },
  getFormatFerment: (id:number|string) => {
    return S_App.getByID(S_Categories.formats_ferments,id);
  },

};
export default S_Categories;