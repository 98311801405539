import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants Yaourts

// Styles
import './StatFormat.scss';

type StatFormatProps = {
  count: number;
  format: any;
  tag?: string;
}

function StatFormat(props:StatFormatProps) {

  // props
  let {count,format,tag} = props;

  // state

  // autres variables

  if(!format)
    return null;

  let cssClass = format.cssClass;
  if(format.id >= 20 && format.id < 30 && format.id!==24)
    cssClass = 'color-yaourt';
  if(format.id >= 30 && format.id < 40)
    cssClass = 'color-fromage';
  if(format.id >= 40 && format.id < 50)
    cssClass = 'color-dessert';

  if(count===undefined || count===0)
    cssClass += ' zero';

  return (
    <div className={'stat '+ cssClass}>
      <span className="count">{ count }</span>
      {!tag ? <span className="label">{ format.name }</span> : <span className="label">{ tag }</span>}
    </div>
  );
}

export default StatFormat;
