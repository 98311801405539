// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import TextField from '@material-ui/core/TextField';

// Composants génériques

// Composants Yaourts

// Styles
// import './ListLaits.scss';

type ListFermentsSuggestionsProps = {
  fermentSuggestions: any[];
  onClickRemove: any;
  onClickAdd: any;
  onUpdate: any;
}

function ListFermentsSuggestions(props:ListFermentsSuggestionsProps) {

  // props
  let {fermentSuggestions,onClickRemove,onClickAdd,onUpdate} = props;

  // state

  // autres variables


  return (
    <>
    <div className="list-ferments-suggestions">
      <div className="float-right">
        <div className={'addferment'} title="Ajouter un ferment" onClick={(e) => onClickAdd('Nouveau')}><ControlPointIcon /></div>
      </div>
      <h2>Suggestions de ferments (configurables)</h2>
      <div>
        {fermentSuggestions.map((ferment,index) => (
          <Grid className="list-ferments-suggestion" container spacing={2} justify="center" alignItems="center" key={index}>
            <Grid item xs={10}>
              <TextField label={'Nom du ferment'} className="textfield" fullWidth={true}
                value={ferment} 
                onChange={(e) => onUpdate(ferment,e.target.value)}/>
            </Grid>
            <Grid item xs={2} className="text-right">
              <div className={'removeferment'} title="Supprimer le ferment" onClick={(e) => onClickRemove(ferment)}><RemoveCircleOutlineIcon /></div>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
    </>
  );
}

export default ListFermentsSuggestions;
