// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

// Composants génériques

// Composants Yaourts
import S_Notes      from '../services/S_Notes';
import Notes        from '../components/Notes';

// Styles
// import './PanelNotes.scss';

type PanelNotesProps = {

}

function PanelNotes(props:PanelNotesProps) {

  // props
  let { } = props;

  // state
  const [notes, setNotes] = useState([]);

  // chargement des donnees
  useEffect(() => {
    S_Notes.fetch(() => {
      setNotes([...S_Notes.notes]);
    });
  }, []);

  // autres variables


  // fonctions
  function changeNote(note,value) {
    S_Notes.updateNote(value,note,(foundData) => {
      if(!foundData)
        return;
      setNotes([...S_Notes.notes]);
    });
  }
  function addNote(){
    S_Notes.addNote(() => {
      setNotes([...S_Notes.notes]);
    });
  }
  function removeNote(note){
    S_Notes.removeNote(note,() => {
      setNotes([...S_Notes.notes]);
    });
  }


  return (
    <Container fixed className="panel-contents">
      <Notes 
        notes={notes}
        onClickRemove={removeNote}
        onAskUpdate={changeNote} />
      <div className="panel-contents-footer text-center mv-2">
        <Fab aria-label="Ajouter" onClick={addNote}><AddIcon /></Fab>
      </div>
    </Container>
  );
}

export default PanelNotes;
