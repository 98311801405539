import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants Yaourts
import TasteType    from   '../elements/TasteType';

// Styles
import './CookType.scss';

type CookTypeProps = {
  type: any;          // categorie format
  tag?: string;
  taste: string;
  appreciation?: any;  // Element
  variant?: any;
}

function CookType(props:CookTypeProps) {

  // props
  let {type,tag,taste,appreciation,variant} = props;

  // si pas d'info sur le type
  if(!type)
    return null;

  const Appreciation = appreciation;

  let cssClass = type.cssClass!=='auto' ? type.cssClass : '';
  if(type.id >= 20 && type.id < 30 && type.id!==24)
    cssClass = 'color-yaourt';
  if(type.id >= 30 && type.id < 40)
    cssClass = 'color-fromage';
  if(type.id >= 40 && type.id < 50)
    cssClass = 'color-dessert';

  if(variant===undefined){
    return (
      <div className={'type ' + cssClass}>
        {!!tag ? tag : type.name}
        { taste !== '' ? <TasteType taste={taste} /> : null }
        {appreciation ? Appreciation : null}
      </div>
    );
  }

  if(variant===1)
    return (
      <div className={'type vertical ' + cssClass}>
        {type.name}
        { taste !== '' ? <TasteType taste={taste} /> : null }
        {appreciation ? Appreciation : null}
      </div>
    );

  return (
    <div className={'type small ' + cssClass}>
      {!!tag ? tag : type.name}
      { taste !== '' ? <TasteType taste={taste} /> : null }
      {appreciation ? Appreciation : null}
    </div>
  );
  
}

export default CookType;
