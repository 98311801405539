const C_FERMENTS_FORMATS = [
  {
    'id':1,
    'name':'Petit lait',
    'cssClass':'color-petit_lait',
    'isPetitLait':true,
    'order':1
  },
  {
    'id':2,
    'name':'Sachet',
    'cssClass':'color-sachet',
    'order':2
  },
  {
    'id':20,
    'name':'Yaourt',
    'cssClass':'color-yaourt',
    'order':3
  },
  {
    'id':30,
    'name':'Fromage',
    'cssClass':'color-fromage',
    'order':4
  }
];
export {C_FERMENTS_FORMATS};