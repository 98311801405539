import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import OpacityIcon from '@material-ui/icons/Opacity';

// Styles
import './MilkType.scss';

type MilkTypeProps = {
  baseLait: any;
  size?: string;    // default | small
  name?: string;    // nom marque
}

function MilkType(props:MilkTypeProps) {

  // props
  let {baseLait,size,name} = props;

  // state

  // autres variables

  if(!baseLait)
    return null;

  return (
    <i className={'milktype size-'+size}>
      <OpacityIcon className={baseLait.cssClass}/>
      <span className={baseLait.cssClass}>{baseLait.name}</span>
      {!!name ? <span className={baseLait.cssClass}>{name}</span> : null}
    </i>
  );
}

export default MilkType;
