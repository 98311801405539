import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import BlurOnIcon from '@material-ui/icons/BlurOn';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import PieChartIcon from '@material-ui/icons/PieChart';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

// Styles
import './FermentType.scss';

type FermentTypeProps = {
  type: any;
  size?: string;    // default | small
  displayType?: boolean;
  name?: string;    // nom du ferment
}

function FermentType(props:FermentTypeProps) {

  // props
  let {type,size,displayType,name} = props;

  // state

  // autres variables

  if(!type)
    return (
      <i className={'ferment-type size-'+size}>
        <BlurOnIcon />
      </i>
    );

  let Icon = null;
  if(type.cssClass==='color-petit_lait')
    Icon = <InvertColorsIcon className={type.cssClass} />;
  if(type.cssClass==='color-sachet')
    Icon = <MailOutlineIcon className={type.cssClass} />;
  if(type.cssClass==='color-yaourt' || type.cssClass==='color-skyr')
    Icon = <LocalDrinkIcon />;
  if(type.cssClass==='color-fromage')
    Icon = <PieChartIcon className={type.cssClass} />;

  return (
    <i className={'ferment-type size-'+size}>
      <BlurOnIcon className={type.cssClass} />
      {Icon}
      {!!displayType ? <span className={type.cssClass}>{type.name}</span> : null}
      {!!name ? <span className={type.cssClass}>{name}</span> : null}
    </i>
  );
}

export default FermentType;
