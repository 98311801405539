import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';

// Composants génériques

// Composants Yaourts
import S_Datas       from   '../services/S_Datas';
import S_Categories  from   '../services/S_Categories';
import {I_Data}      from   '../services/I_Data';
import MilkType      from   '../elements/MilkType';
import TasteType     from   '../elements/TasteType';
import Ferment       from   '../elements/Ferment';
import Appreciation  from   '../elements/Appreciation';
import CookType      from   '../elements/CookType';

// Styles
import './PreparationCardArchive.scss';

type PreparationCardArchiveProps = {
  data: I_Data;
  onClick?: any;  // func
}

function PreparationCardArchive(props:PreparationCardArchiveProps) {

  // props
  let {data,onClick} = props;

  let isExpired = S_Datas.isExpired(data);
  let formatFerment = data ? S_Categories.getFormatFerment(data.category_formatID) : undefined;
  let formatPot = data ? S_Categories.getFormat(data.category_formatID) : undefined;
  let lait = S_Categories.getLait(data.baseLaitID);
  let taste = data.taste;
  let isDessert = formatPot===40;
  
  return (
    <Paper className={'preparationCardArchive' + (isExpired ? ' expired' : '') + (formatPot ? ' '+formatPot.cssClass : '')}
        elevation={0}
        onClick={onClick}>

      {data.isFerment ? <div className="badge-ferment">ferment</div> : null}
      <CookType type={formatPot} tag={data.dataTypeTag} taste={data.taste} variant={0} />

    </Paper>
  );
}

export default PreparationCardArchive;
