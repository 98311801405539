import S_App          from   '../../services/S_App';
import S_Categories   from   './S_Categories';
import {I_Data}       from   './I_Data';

const FRIGO_DELAY_IN_DAYS = 8;
const CONGEL_DELAY_IN_DAYS = 180;
const DECONGEL_DELAY_IN_DAYS = 2;

let TEST_DATAS = [];


let S_Datas = {
  STORAGE_KEY_DATAS : 'datas',
  STORAGE_KEY_COMPTEUR : 'compteur',

  STATUS_FRIGO : 'isInFrigo',
  STATUS_CONGEL : 'isInCongel',
  STATUS_DECONGEL : 'isInDecongel',
  STATUS_FERMENTATION : 'isInFermentation',
  STATUS_EGOUTTAGE : 'isInEgouttage',
  STATUS_PLACARD : 'isInPlacard',
  STATUS_ARCHIVED : 'archived',

  datas : [],        // charge toutes les donnees
  compteur : 0,      // compteur d'id
  

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch()...');
    var datas = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_DATAS];
    if(datas){
      S_Datas.datas = JSON.parse(datas);
    }else{
      S_Datas.datas = TEST_DATAS;
    }
    var compt = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_COMPTEUR];
    if(compt!==undefined){
      S_Datas.compteur = parseInt(compt);
    }else{
      S_Datas.compteur = 0;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch() datas',S_Datas.datas);
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fetch() compteur',S_Datas.compteur);

    S_Datas.fixDataTypeTag(cb);
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_DATAS] = JSON.stringify(S_Datas.datas);
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_COMPTEUR] = S_Datas.compteur;
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- store() datas',S_Datas.datas); 
    if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- store() compteur',S_Datas.compteur);  
    if(cb)
      cb();
  },
  fixDataTypeTag: (cb) =>{
    // Mai 2021 : remplacement de certains category_formatID par 20 (yaourt) + dataTypeTag
    let hasChanged = false;
    for(var i = 0; i < S_Datas.datas.length; i++){
      let data = S_Datas.datas[i];
      let dataType = S_Categories.getFormat(data.category_formatID);
      if(!dataType || !dataType.to_be_replaced_by)
        continue;
      let tagToSelect = dataType.to_be_replaced_by;
      // if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fixDataTypeTag() data',data,tagToSelect);
      data.category_formatID = 20;  // yaourt
      data.dataTypeTag = tagToSelect;
      S_Datas.datas[i] = data;
      // if(S_App.isLogDebug) console.log('-- S_Datas.tsx -- fixDataTypeTag() new data',S_Datas.datas[i]);
      hasChanged = true;
    }
    S_Datas.store(cb);
  },

  getStatus: (data:I_Data) => {
    if(!data)
      return;

    if(data.archived)
      return S_Datas.STATUS_ARCHIVED;

    if(data.decongelDate)
      return S_Datas.STATUS_DECONGEL;

    if(data.congelDate)
      return S_Datas.STATUS_CONGEL;

    if(data.frigoDate)
      return S_Datas.STATUS_FRIGO;

    if(data.egouttageDate)
      return S_Datas.STATUS_EGOUTTAGE;

    if(data.fermentationDate)
      return S_Datas.STATUS_FERMENTATION;

    return S_Datas.STATUS_PLACARD;
  },
  formatDateForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  },


  newData(status:string,cb) {
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    console.log('newData',status);
    S_Datas.compteur++;
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: S_Datas.compteur,
      idExtractedFrom: S_Datas.compteur,
      baseName: '',
      fermentTypeID: undefined,
      baseLaitID: S_Categories.laits[0].id,
      baseLaitName: '',
      ml: 1000,
      fermentationIncrement: 0,
      isFerment: false,
      isOriginFerment: false,
      category_formatID:  undefined,
      dataTypeTag: undefined,
      fermentParentID: undefined,
      fermentationDate: !status||status==='isInFermentation' ? currentDate : undefined,
      egouttageDate: status!=='isInEgouttage' ? undefined : currentDate,
      frigoDate: status!=='isInFrigo' ? undefined : currentDate,
      congelDate: status!=='isInCongel' ? undefined : currentDate,
      decongelDate: status!=='isInDecongel' ? undefined : currentDate,
      notes: '',
      quantity: 1,
      quantityCreated: 1,
      archived: false,
      fermentationTimeInHours: undefined,
      egouttageTimeInHours: undefined
    };
    if(!!status && status==='isInPlacard'){  // a priori c'est un sachet de ferment
      newOne.isFerment = true;
      newOne.isOriginFerment = true;
      newOne.category_formatID = 2;
    }
    console.log('Add...',newOne);
    console.log(S_Datas.datas);
    S_Datas.datas.push(newOne);
    S_Datas.store(() => {
      if(cb) cb(newOne);
    });
  },
  newDataFrom(data:I_Data,laitID:number,ml:number,cb) {
    // cree une nouvelle data (nouveau bain) et l'ajoute au set de donnnees, le save
    S_Datas.compteur++;
    let currentDate = (new Date()).getTime();
    let formatFerment = data.category_formatID;
    if(data.category_formatID > 20 && data.category_formatID < 30)
      formatFerment = 20;
    if(data.category_formatID > 30 && data.category_formatID < 40)
      formatFerment = 30;
    if(data.category_formatID > 40 && data.category_formatID < 50)
      formatFerment = 40;
    if(data.category_formatID > 50)
      formatFerment = 50;

    let newOne = {
      id: S_Datas.compteur,          // c'est un nouvel id
      idExtractedFrom: S_Datas.compteur,

      baseName: data.baseName,
      fermentTypeID: formatFerment,
      baseLaitID: laitID!==undefined ? laitID : data.baseLaitID,
      baseLaitName: data.baseLaitName,
      ml: !!ml ? ml : data.ml,
      fermentationIncrement: data.fermentationIncrement,
      fermentParentID: data.idExtractedFrom,      // on indique le bain parent

      isFerment: false,
      isOriginFerment: false,
      category_formatID:  undefined,
      dataTypeTag: undefined,
      taste: '',

      fermentationDate: currentDate,
      egouttageDate: undefined,
      frigoDate: undefined,
      congelDate: undefined,
      decongelDate: undefined,
      notes: '',
      quantity: 1,
      quantityCreated: 1,
      archived: false,
      fermentationTimeInHours: undefined,
      egouttageTimeInHours: undefined
    };
    console.log('Add...',newOne);
    console.log(S_Datas.datas);
    S_Datas.datas.push(newOne);
    S_Datas.store(cb);
  },
  copyData(data:I_Data,quantity,cb){
    if(!data){
      if(cb) cb();
      return;
    }
    // cree une nouvelle data et l'ajoute au set de donnnees, le save
    S_Datas.compteur++;
    let newOne = {
      id: S_Datas.compteur,
      idExtractedFrom: data.idExtractedFrom,
      baseName: data.baseName,
      fermentTypeID: data.fermentTypeID,
      baseLaitID: data.baseLaitID,
      baseLaitName: data.baseLaitName,
      ml: data.ml,
      fermentationIncrement: data.fermentationIncrement,
      isFerment: data.isFerment,
      isOriginFerment: data.isOriginFerment,
      category_formatID:  data.category_formatID,
      dataTypeTag: data.dataTypeTag,
      fermentParentID: data.fermentParentID,
      fermentationDate: data.fermentationDate,
      egouttageDate: data.egouttageDate,
      frigoDate: data.frigoDate,
      congelDate: data.congelDate,
      decongelDate: data.decongelDate,
      notes: data.notes,
      quantity: quantity,
      quantityCreated: data.quantityCreated,
      archived: false,
      fermentationTimeInHours: data.fermentationTimeInHours,
      egouttageTimeInHours: data.egouttageTimeInHours
    };
    console.log('Copy as...',newOne);
    // console.log(S_Datas.datas);
    S_Datas.datas.push(newOne);
    S_Datas.store(() => {
      if(cb) cb(newOne);
    });
  },
  updateData(value,field,data,cb){
    if(!S_Datas.datas || S_Datas.datas.length===0 || !data){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Datas.datas.length; i++){
      if(S_Datas.datas[i].id === data.id){
        foundData = S_Datas.datas[i];
        if('fermentationIncrement'===field || 'baseLaitID'===field || 'fermentTypeID'===field || 'quantity'===field || 'quantityCreated'===field || 'fermentParentID'===field || 'fermentationTimeInHours'===field || 'egouttageTimeInHours'===field){
          foundData[field] = parseInt(value);
        }else if('fermentationDate'===field || 'egouttageDate'===field || 'frigoDate'===field || 'congelDate'===field || 'decongelDate'===field){
          foundData[field] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
        }else{
          foundData[field] = value;
        }
        break;
      }
    }
    console.log('Update',field,'as',value,'...',foundData);
    // console.log(S_Datas.datas);
    S_Datas.store(() => {
      if(cb) cb(foundData);
    });
  },
  delete(data,cb){
    if(!S_Datas.datas || S_Datas.datas.length===0 || !data){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Datas.datas.length; i++){
      if(S_Datas.datas[i].id === data.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Datas.datas.splice(foundDataIndex,1);
    }
    console.log('Delete...',foundDataIndex);
    console.log(S_Datas.datas);
    S_Datas.store(cb);
    if(cb) cb();
  },
  getData(id){
    if(!S_Datas.datas || S_Datas.datas.length===0 || id===undefined){
      return;
    }
    for(var i = 0; i < S_Datas.datas.length; i++){
      if(S_Datas.datas[i].id+'' === id+''){
        return S_Datas.datas[i];
      }
    }
    return;
  },
  newPetitLait(data,quantity,cb) {
    if(!data)
      return;
    S_Datas.compteur++;
    let newOne = {
      id: S_Datas.compteur,
      idExtractedFrom: S_Datas.compteur,
      baseName: data.baseName,
      fermentTypeID: 1,
      baseLaitID: data.baseLaitID,
      baseLaitName: data.baseLaitName,
      ml: data.ml,
      fermentationIncrement: data.fermentationIncrement,
      isFerment: true,
      isOriginFerment: false,
      category_formatID:  1,
      dataTypeTag: undefined,
      fermentParentID: data.fermentParentID,    // c le parent de la preparation
      fermentationDate: data.fermentationDate,
      egouttageDate: data.egouttageDate,
      frigoDate: data.frigoDate,
      congelDate: undefined,
      decongelDate: undefined,
      notes: undefined,
      quantity: quantity!==undefined ? quantity : 1,
      quantityCreated: quantity!==undefined ? quantity : 1,
      archived: false,
      fermentationTimeInHours: undefined,
      egouttageTimeInHours: undefined
    };
    console.log('Add...',newOne);
    console.log(S_Datas.datas);
    S_Datas.datas.push(newOne);
    S_Datas.store(cb);
  },


  isExpired(data) {
    // let status = S_Datas.getStatus(data);
    let limitDate = undefined;
    let isExpired = false;
    if(data.frigoDate && !data.congelDate)
      limitDate = new Date(data.frigoDate + (FRIGO_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    if(data.congelDate && !data.decongelDate)
      limitDate = new Date(data.congelDate + (CONGEL_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    if(data.decongelDate)
      limitDate = new Date(data.decongelDate + (DECONGEL_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    if(limitDate && limitDate.getTime() <= (new Date()).getTime())
      isExpired = true;
    return isExpired;
  },

  getLimitDate(data) {
    let limitDate = undefined;
    // let isExpired = false;
    if(data.frigoDate && !data.congelDate)
      limitDate = new Date(data.frigoDate + (FRIGO_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    if(data.congelDate && !data.decongelDate)
      limitDate = new Date(data.congelDate + (CONGEL_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    if(data.decongelDate)
      limitDate = new Date(data.decongelDate + (DECONGEL_DELAY_IN_DAYS * 24 * 60 * 60 * 1000));
    return limitDate;
  },
  getLastDate(data) {
    let lastDate = undefined;
    try{
      if(data.fermentationDate)
        lastDate = new Date(data.fermentationDate);
      if(data.egouttageDate)
        lastDate = new Date(data.egouttageDate);
      if(data.frigoDate)
        lastDate = new Date(data.frigoDate);
      if(data.congelDate)
        lastDate = new Date(data.congelDate);
      if(data.decongelDate)
        lastDate = new Date(data.decongelDate);
    }catch(err){
      console.error('Error in getLastDate',err);
    }
    return lastDate;
  },

  getDatasOfYear(datas,oneYear:number){
    if(!datas || datas.length===0)
      return [];

    let filtered = [];
    for(var i=0;i<datas.length;i++){
      let data = datas[i];

      if(S_Datas.getStatus(data) !== S_Datas.STATUS_ARCHIVED)
        continue;

      let someDate = data.fermentationDate;
      if(!someDate)
        someDate = data.egouttageDate;
      if(!someDate)
        someDate = data.frigoDate;
      if(!someDate)
        someDate = data.congelDate;
      if(!someDate)
        someDate = data.decongelDate;

      let dateObject = someDate ? new Date(someDate) : null;
      let year =  dateObject ? dateObject.toLocaleDateString("fr-FR", { year: 'numeric', month: undefined, day: undefined, weekday: undefined }) : null;
      if(oneYear && year+'' !== oneYear+'')
        continue;
      if(!oneYear && year!==undefined)
        continue;
      filtered.push(data);
    }
    return filtered;
  },

  sortByLastDate(datas){
    let arrayOrdered = [].concat(datas).sort((a, b) => {
      let lastDateA = S_Datas.getLastDate(a);
      let lastDateB = S_Datas.getLastDate(b);
      return lastDateB > lastDateA ? -1 : 1;
    });
    return arrayOrdered;
  },

  getMilkQuantityOfDatas(datas){
    if(!datas || datas.length===0)
      return [];

    let total = undefined;
    for(var i=0;i<datas.length;i++){
      let data = datas[i];

      if(!!data.ml)
        total = total === undefined ? parseInt(data.ml) : total + parseInt(data.ml);
    }
    return total;
  },
  getQuantitiesOfDatas(datas){
    if(!datas || datas.length===0)
      return [];

    let total = undefined;
    for(var i=0;i<datas.length;i++){
      let data = datas[i];

      if(data.isFerment)
        continue;

      if(!!data.quantityCreated)
        total = total === undefined ? parseInt(data.quantityCreated) : total + parseInt(data.quantityCreated);
    }
    return total;
  },

  getDatasOfFormat(datas,formatID:number){
    if(!datas || datas.length===0)
      return [];

    let filtered = [];
    for(var i=0;i<datas.length;i++){
      let data = datas[i];

      let format = data.category_formatID;

      if(formatID!==undefined && format+'' !== formatID+'')
        continue;
      if(formatID===undefined && format!==undefined)
        continue;
      filtered.push(data);
    }
    return filtered;
  },
  getDatasOfFormatAndTag(datas,formatID:number,tag:string){
    if(!datas || datas.length===0)
      return [];

    let filtered = [];
    for(var i=0;i<datas.length;i++){
      let data = datas[i];

      let format = data.category_formatID;

      if(formatID!==undefined && format+'' !== formatID+'')
        continue;
      if(formatID===undefined && format!==undefined)
        continue;
      if(!tag || tag!==data.dataTypeTag)
        continue;
      filtered.push(data);
    }
    return filtered;
  },

  isSingle(data:I_Data){
    return data.quantity === undefined || data.quantity === null || data.quantity <= 1;
  },


  // recupere la liste des annees (de last date) pour un ensemble donne
  getYears(datas:I_Data[]){
    if(!datas || datas.length===0)
      return [];
    let years = [];
    for(var i=0;i<datas.length;i++){
      let object = datas[i];
      let date = S_Datas.getLastDate(object);
      let year = date ? date.getFullYear() : undefined;
      if(year!==undefined && years.indexOf(year)===-1)
        years.push(year);
    }
    years.sort(function(a, b) {
      return a - b;
    });
    return years;
  },

  // renvoie toutes les datas (archivees ou pas) qui proviennent du bain, sauf celui la meme
  getDatasOfBain_same(bainID:number,currentID:number){
    // console.log('getDatasOfBain() - ',bainID,S_Datas.datas);
    if(!S_Datas.datas || S_Datas.datas.length===0 || bainID===undefined || currentID===undefined)
      return [];
    let results = [];
    for(var i=0;i<S_Datas.datas.length;i++){
      let data = S_Datas.datas[i];
      // console.log('getDatasOfBain() - ',data.idExtractedFrom,data.fermentParentID,bainID,data);
      if(!data)
        continue;
      if(data.id === currentID)
        continue;
      if(data.idExtractedFrom!==undefined && data.idExtractedFrom === bainID)
        results.push(data);
    }
    return results;
  },

  // renvoie toutes les datas (archivees ou pas) qui proviennent du parent, sauf celui la meme
  getDatasOfBain_sameParent(parentID:number,currentID:number){
    // console.log('getDatasOfBain() - ',bainID,S_Datas.datas);
    if(!S_Datas.datas || S_Datas.datas.length===0 || parentID===undefined)
      return [];
    let results = [];
    for(var i=0;i<S_Datas.datas.length;i++){
      let data = S_Datas.datas[i];
      // console.log('getDatasOfBain() - ',data.idExtractedFrom,data.fermentParentID,bainID,data);
      if(!data)
        continue;
      if(data.id === currentID)
        continue;
      if(data.fermentParentID!==undefined && data.fermentParentID === parentID)
        results.push(data);
    }
    return results;
  },

  getDatasOfBain_childs(bainID:number){
    // console.log('getDatasOfBain() - ',bainID,S_Datas.datas);
    if(!S_Datas.datas || S_Datas.datas.length===0 || bainID===undefined)
      return [];
    let results = [];
    for(var i=0;i<S_Datas.datas.length;i++){
      let data = S_Datas.datas[i];
      // console.log('getDatasOfBain() - ',data.idExtractedFrom,data.fermentParentID,bainID,data);
      if(!data)
        continue;
      if(data.id === data.idExtractedFrom && data.id === bainID)
        continue;
      if(data.fermentParentID!==undefined && data.fermentParentID === bainID)
        results.push(data);
    }
    return results;
  },
  // renvoie toutes les datas (archivees ou pas) parente
  getDatasOfBain_parent(parentID:number){
    if(!S_Datas.datas || S_Datas.datas.length===0 || parentID===undefined)
      return [];
    let results = [];
    for(var i=0;i<S_Datas.datas.length;i++){
      let data = S_Datas.datas[i];
      // console.log('getDatasOfBain() - ',data.idExtractedFrom,data.fermentParentID,bainID,data);
      if(!data)
        continue;
      if(data.id === parentID || (data.idExtractedFrom!==undefined && data.idExtractedFrom === parentID))
        results.push(data);
    }
    return results;
  }

};
export default S_Datas;