// Composant version 2021
// ----------------------------------

import React, {useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Modal  from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StarIcon from '@material-ui/icons/Star';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App  from '../../services/S_App';
import S_Date from '../../services/S_Date';

// Composants DLC
import S_Categories     from '../services/S_Categories';
import S_Datas          from '../services/S_Datas';
import S_Ferments_Suggestions  from '../services/S_Ferments_Suggestions';
import {I_Data}         from '../services/I_Data';
import Preparation      from   '../elements/Preparation';
import Ferment          from   '../elements/Ferment';
import MilkType         from   '../elements/MilkType';
import DataFerment      from   '../elements/DataFerment';
import DataPreparation  from   '../elements/DataPreparation';
import ListItems        from   '../components/ListItems';
import ListItemsArchived   from   '../components/ListItemsArchived';
import Tree             from   '../components/Tree';

// Styles
import './Modal.scss';

type ModalDataEditionProps = {
  data: I_Data;

  isModalOpened: boolean;
  closeModal: any;            // function close

  onFieldChange: any;         // function change value
  onClickDelete: any;        // function
}

function ModalDataEdition(props:ModalDataEditionProps) {

  // props
  let {data,isModalOpened,closeModal,onFieldChange,onClickDelete} = props;

  // state

  if(!data)
    return null;

  // console.log('data',data);

  // autres variables
  let appreciations = [
    {value:0,label:''},
    {value:1,label:'Jeté, beurk !'},
    {value:2,label:'Mangeable, mais nul'},
    {value:3,label:'Neutre'},
    {value:4,label:'Bon'},
    {value:5,label:'Top !'}
  ];


  let formatsArray = S_App.sortByOrder(S_Categories.formats);
  let formatsFermentsArray = S_App.sortByOrder(S_Categories.formats_ferments);
  let preparationFormatsArray = data.isFerment ? formatsFermentsArray : formatsArray;
  let status = S_Datas.getStatus(data);

  let dataType = S_Categories.getFormat(data.category_formatID);
  let dataTypeTagsOptions = dataType && dataType.tags ? dataType.tags : [];

  

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents modal-contents--edit">

        <div className="absolute-top-right">
          <Fab aria-label="Fermer" size="small" onClick={closeModal} title="Fermer la vue"><CloseIcon /></Fab>
        </div>

        <div className={'data-details '} >

          <div className="modal-form">
            <form className="edition" noValidate autoComplete="off">

              { status!=S_Datas.STATUS_FERMENTATION && status!=S_Datas.STATUS_EGOUTTAGE ? (
              <>
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} className="text-center">
                  <h2>Préparation</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    <h3>Description</h3>
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={3}>
                      {data.isFerment || data.category_formatID === 1 || data.category_formatID === 2 ? (
                        <DataFerment data={data} asA={'preparation'}/>
                      ) : (
                        <DataPreparation data={data} />
                      )}
                    </Grid>
                    <Grid item xs={9}>
                      <FormControlLabel label="C'est un ferment"
                        control={
                          <Switch
                            checked={data.isFerment}
                            onChange={(e) => onFieldChange(e.target.checked,'isFerment',data)}
                            name="isFerment"/>
                        } />
                      {data.isFerment ? (
                      <>
                      <FormControlLabel label="C'est un ferment d'origine"
                        control={
                          <Switch
                            checked={data.isOriginFerment}
                            onChange={(e) => onFieldChange(e.target.checked,'isOriginFerment',data)}
                            name="isOriginFerment"/>
                        } />
                      <TextField label="Nom du ferment" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.baseName} 
                        onChange={(e) => onFieldChange(e.target.value,'baseName',data)} />
                      { !data.baseName && S_Ferments_Suggestions.ferments.length>0 ? (
                      <div className="suggestions">
                        { S_Ferments_Suggestions.ferments.map((ferment,index) => <div key={index} className="suggestion" onClick={(e) => onFieldChange(ferment,'baseName',data)}>{ferment}</div>) }
                      </div>
                      ) : null }
                      </>
                      ) : null}
                      <FormControl size="small" fullWidth>
                        <InputLabel id="label-format">Type de préparation</InputLabel>
                        <Select labelId="label-format" labelWidth={80} value={data.category_formatID}
                          onChange={(e) => onFieldChange(e.target.value,'category_formatID',data)}>
                          <MenuItem value={undefined}></MenuItem>
                          { preparationFormatsArray.map( (format,index) => <MenuItem value={format.id} key={index}>{ format.name }{ !!format.to_be_replaced_by ? ' (old)' : ''}</MenuItem> ) }
                        </Select>
                      </FormControl>
                      {!data.isFerment && dataTypeTagsOptions && dataTypeTagsOptions.length>0 ? (
                        <FormControl size="small" fullWidth>
                          <InputLabel id="label-format">Tag</InputLabel>
                          <Select labelId="label-format" labelWidth={80} value={data.dataTypeTag}
                            onChange={(e) => onFieldChange(e.target.value,'dataTypeTag',data)}>
                            <MenuItem value={undefined}></MenuItem>
                            { dataTypeTagsOptions.map( (tag,index) => <MenuItem value={tag} key={index}>{ tag }</MenuItem> ) }
                          </Select>
                        </FormControl>
                      ):null}
                      <TextField label="Saveur" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.taste} 
                        onChange={(e) => onFieldChange(e.target.value,'taste',data)}/>
                      <TextField label="Nombre de pots / sachets restants" type="number" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.quantity} 
                        onChange={(e) => onFieldChange(e.target.value,'quantity',data)}/>
                      <TextField label="Nombre de pots / sachets créés" type="number" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.quantityCreated} 
                        onChange={(e) => onFieldChange(e.target.value,'quantityCreated',data)}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    <h3>Appréciation</h3>
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={3}>
                      
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="label-appreciation" className="selectLabel">Appréciation</InputLabel>
                        <Select labelId="label-appreciation" labelWidth={80} value={data.appreciation}
                          onChange={(e) => onFieldChange(e.target.value,'appreciation',data)}>
                          { appreciations.map( (value,index) => <MenuItem value={value.value} key={index}>{ value.label }</MenuItem> ) }
                        </Select>
                      </FormControl>
                      <TextField label="Notes" multiline rows="4" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.notes} 
                        onChange={(e) => onFieldChange(e.target.value,'notes',data)}/>
                      <FormControlLabel label="Archivé"
                        control={
                          <Switch
                            checked={data.archived}
                            onChange={(e) => onFieldChange(e.target.checked,'archived',data)}
                            name="archived"/>
                        } />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br/>
              </>
              ) : null}

              {!data.isOriginFerment ? (
              <Grid container spacing={1} justify="center"  className={status!=S_Datas.STATUS_FERMENTATION && status!=S_Datas.STATUS_EGOUTTAGE ? 'not-prior' : ''}>
                <Grid item xs={12} className="text-center">
                  <h2>Ingrédients</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    <h3>Ferment</h3>
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={3}>
                      <DataFerment data={data} />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField label="Nom du ferment" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.baseName} 
                        onChange={(e) => onFieldChange(e.target.value,'baseName',data)} />
                      { !data.baseName && S_Ferments_Suggestions.ferments.length>0 ? (
                      <div className="suggestions">
                        { S_Ferments_Suggestions.ferments.map((ferment,index) => <div key={index} className="suggestion" onClick={(e) => onFieldChange(ferment,'baseName',data)}>{ferment}</div>) }
                      </div>
                      ) : null }
                      <FormControl size="small" fullWidth>
                        <InputLabel id="label-typeferment" className="selectLabel">Type de ferment</InputLabel>
                        <Select labelId="label-type" labelWidth={80} value={data.fermentTypeID}
                          onChange={(e) => onFieldChange(e.target.value,'fermentTypeID',data)}>
                          { formatsFermentsArray.map( (type,index) => <MenuItem value={type.id} key={index}>{ type.name }</MenuItem> ) }
                        </Select>
                      </FormControl>
                      <TextField label="Nombre de fermentation" type="number" InputLabelProps={{shrink:true}} size="small" fullWidth
                        value={data.fermentationIncrement} 
                        onChange={(e) => onFieldChange(e.target.value,'fermentationIncrement',data)}/>
                      <TextField label="ID du bain du ferment" type="number" InputLabelProps={{shrink:true}} size="small" fullWidth disabled
                        value={data.fermentParentID}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    <h3>Lait</h3>
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={3}>
                      <MilkType baseLait={S_Categories.getLait(data.baseLaitID)} />
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="label-baselait">Type de lait</InputLabel>
                        <Select labelId="label-baselait" labelWidth={80} value={data.baseLaitID} name="baselait"
                          onChange={(e) => onFieldChange(e.target.value,'baseLaitID',data)} >
                          { S_Categories.laits.map( (lait,index) => <MenuItem value={lait.id} key={index}>{ lait.name }</MenuItem> ) }
                        </Select>
                      </FormControl>
                      <TextField label="Nom du lait" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.baseLaitName} 
                        onChange={(e) => onFieldChange(e.target.value,'baseLaitName',data)} />
                      <TextField label="Quantité de lait (ml)" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.ml}  
                        onChange={(e) => onFieldChange(e.target.value,'ml',data)}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              ) : null}


              { status==S_Datas.STATUS_FERMENTATION || status==S_Datas.STATUS_EGOUTTAGE ? (
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} className="text-center">
                  <h2>Préparation</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    <h3>Appréciation</h3>
                  </div>
                  <Grid container justify="center">
                    <Grid item xs={3}>
                      
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="label-appreciation" className="selectLabel">Appréciation</InputLabel>
                        <Select labelId="label-appreciation" labelWidth={80} value={data.appreciation}
                          onChange={(e) => onFieldChange(e.target.value,'appreciation',data)}>
                          { appreciations.map( (value,index) => <MenuItem value={value.value} key={index}>{ value.label }</MenuItem> ) }
                        </Select>
                      </FormControl>
                      <TextField label="Notes" multiline rows="4" size="small" fullWidth InputLabelProps={{shrink:true}} 
                        value={data.notes} 
                        onChange={(e) => onFieldChange(e.target.value,'notes',data)}/>
                      <FormControlLabel label="Archivé"
                        control={
                          <Switch
                            checked={data.archived}
                            onChange={(e) => onFieldChange(e.target.checked,'archived',data)}
                            name="archived"/>
                        } />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              ):null}

              <br/>

              <Grid container spacing={1} justify="center">
                <Grid item xs={12} className="text-center">
                  <h2>Dates</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label="Fermentation démarrée le" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    value={S_Date.formatDateForMaterial(data.fermentationDate)} 
                    onChange={(e) => onFieldChange(e.target.value,'fermentationDate',data)}/>
                  <TextField label="Durée de fermentation (en heures)" type="number" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    value={data.fermentationTimeInHours} 
                    onChange={(e) => onFieldChange(e.target.value,'fermentationTimeInHours',data)}/>
                  <TextField label="Egouttage démarré le" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    className={status==S_Datas.STATUS_FERMENTATION?'not-pertinent':''}
                    value={S_Date.formatDateForMaterial(data.egouttageDate)} 
                    onChange={(e) => onFieldChange(e.target.value,'egouttageDate',data)}/>
                  <TextField label="Durée d'égouttage' (en heures)" type="number" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    value={data.egouttageTimeInHours} 
                    onChange={(e) => onFieldChange(e.target.value,'egouttageTimeInHours',data)}/>

                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label="Mis au frigo le" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    className={status==S_Datas.STATUS_FERMENTATION || status==S_Datas.STATUS_EGOUTTAGE?'not-pertinent':''}
                    value={S_Date.formatDateForMaterial(data.frigoDate)}
                    onChange={(e) => onFieldChange(e.target.value,'frigoDate',data)} />
                  <TextField label="Mis au congèl le" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    className={status==S_Datas.STATUS_FERMENTATION || status==S_Datas.STATUS_EGOUTTAGE?'not-pertinent':''}
                    value={S_Date.formatDateForMaterial(data.congelDate)} 
                    onChange={(e) => onFieldChange(e.target.value,'congelDate',data)} />
                  <TextField label="Décongélation le" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
                    className={status==S_Datas.STATUS_FERMENTATION || status==S_Datas.STATUS_EGOUTTAGE?'not-pertinent':''}
                    value={S_Date.formatDateForMaterial(data.decongelDate)} 
                    onChange={(e) => onFieldChange(e.target.value,'decongelDate',data)} />
                </Grid>
              </Grid>
              <br/>

              <TextField label="ID" type="number" InputLabelProps={{shrink:true}} size="small" fullWidth disabled
                value={data.id}/>
              <TextField label="ID du bain" type="number" InputLabelProps={{shrink:true}} size="small" fullWidth disabled
                value={data.idExtractedFrom}/>
              <TextField label="ID du bain parent" type="number" InputLabelProps={{shrink:true}} size="small" fullWidth disabled
                value={data.fermentParentID}/>

              <Tree data={data} />

            </form>
          </div>
        </div>

        <Grid container spacing={2}>

          <Grid item xs={12} className="text-center">
            <Fab variant="extended" onClick={closeModal} color="primary"><CloseIcon /> OK</Fab>
          </Grid>

          <Grid item xs={12}>
            <Button variant="outlined" fullWidth={true} startIcon={<DeleteIcon />} className="wide" onClick={(e) => onClickDelete(data)}>Supprimer</Button>
          </Grid>
        </Grid>

      </div>
    </Modal>
  );
}

export default ModalDataEdition;
