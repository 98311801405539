// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';

// Composants génériques
import S_App  from '../../services/S_App';

// Composants Yaourts
import S_Recipes     from '../services/S_Recipes';
import {I_Recipe}    from '../services/I_Recipe';
import Recipe        from '../elements/Recipe';

// Styles
// import './PanelRecipes.scss';

type PanelRecipesProps = {
  recipes: I_Recipe[];
  // onClickEdit: any;
}

function PanelRecipes(props:PanelRecipesProps) {

  // props
  let {recipes} = props;

  // state

  // autres variables

  return (
    <Container fixed className="panel-contents">

      <div className="mv-2">
        <h2>Recettes</h2>

        {recipes.length>0 ? (
        <div>

          {recipes.map((recipe,index) => (
            <Recipe key={index}
              name={recipe.name}
              subtitle={recipe.subtitle}
              quantity={recipe.quantity}
              ingredients={recipe.ingredients}     
              process={recipe.process}
              notes={recipe.notes} />
          ))}

        </div>
        ) : (
        <div>Aucune recette</div>
        )}
      </div>

    </Container>
  );
}

export default PanelRecipes;
