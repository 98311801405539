import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import KitchenIcon from '@material-ui/icons/Kitchen';
import WavesIcon from '@material-ui/icons/Waves';
import GradientIcon from '@material-ui/icons/Gradient';

// Composants génériques

// Composants Yaourts
import S_Datas        from   '../services/S_Datas';
import {I_Data}       from   '../services/I_Data';
import ListItems      from   '../components/ListItems';

// Styles
import './Dashboard.scss';

type DashboardProps = {
  fermentation: I_Data[];
  egouttage: I_Data[];
  frigo: I_Data[];
  congel: I_Data[];
  decongel: I_Data[];
  placard: I_Data[];

  onClickCreate: any;    // func
  onClickEdit: any;    // func

  onMoveDataToFermentation: any;
  onMoveDataToEgouttage: any;
  onMoveDataToFrigo: any;
  onMoveDataToCongel: any;
  onMoveDataToDecongel: any;
  onMoveDataToPlacard: any;

  onDragStart: any;
  onDragEnd: any;
}

function Dashboard(props:DashboardProps) {

  // props
  let {
    fermentation,egouttage,frigo,congel,decongel,placard,
    onClickCreate,onClickEdit,
    onMoveDataToFermentation,onMoveDataToEgouttage,onMoveDataToFrigo,onMoveDataToCongel,onMoveDataToDecongel,onMoveDataToPlacard,
    onDragStart,onDragEnd
  } = props;

  // state
  const [dragging, setDragging] = useState(false);

  // autres variables


  function dragStart(e,data,from) {
    e.preventDefault();
    e.stopPropagation();
    onDragStart();
    console.log('Drag start - event :',e,e.type);
    if(e.dataTransfer){  // souris
      e.dataTransfer.setData("id", data.id);
      e.dataTransfer.setData("from", from);
      // si IE : e.dataTransfer.setData("text/plain",data)
      console.log('Drag start - data :',data);
    }
    setDragging(true);
  }
  function dragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function dropFermentation(e){
    _dropFermentation(e,false);
  }
  function dropFermentationScrollTop(e){
    _dropFermentation(e,true);
  }
  function _dropFermentation(e, scrollTop:boolean){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    console.log('Drop on fermentation',id);
    let data = S_Datas.getData(id);
    console.log('Drop on fermentation',data);

    onMoveDataToFermentation(data);

    setDragging(false);
    onDragEnd();
  }


  function dropEgouttage(e){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    // console.log('Drop on egouttage',id);
    let data = S_Datas.getData(id);
    // console.log('Drop on egouttage',data);

    onMoveDataToEgouttage(data);

    setDragging(false);
    onDragEnd();
  }


  function dropFrigo(e){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    let from = e.dataTransfer.getData("from");
    // console.log('Drop on frigo',id,from);
    let data = S_Datas.getData(id);
    // console.log('Drop on frigo',data);

    onMoveDataToFrigo(data);

    setDragging(false);
    onDragEnd();
  }


  function dropCongel(e){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    // console.log('Drop on congel',id);
    let data = S_Datas.getData(id);
    // console.log('Drop on congel',data);

    
    onMoveDataToCongel(data);

    setDragging(false);
    onDragEnd();
  }

  function dropDecongel(e){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    // console.log('Drop on decongel',id);
    let data = S_Datas.getData(id);
    // console.log('Drop on decongel',data);
    
    onMoveDataToDecongel(data);

    setDragging(false);
    onDragEnd();
  }

  function dropPlacard(e){
    e.preventDefault();
    e.stopPropagation();
    let id = e.dataTransfer.getData("id");
    // console.log('Drop on congel',id);
    let data = S_Datas.getData(id);
    // console.log('Drop on congel',data);

    
    onMoveDataToPlacard(data);

    setDragging(false);
    onDragEnd();
  }

  return (
    <Grid className="dashboard text-center" container spacing={2} justify="center">
      <Grid item xs={12}>
        
      </Grid>
      <Grid item xs={6}>
        <Paper id="fermentation" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInFermentation')} 
          onDragOver={dragOver} 
          onDrop={dropFermentation}
          aria-label="Ajouter une préparation">
          <WavesIcon />
          <Typography color="textSecondary" align="center"><strong>en Fermentation</strong></Typography>
          <div className="flexer flexer-fermentation">
            <ListItems
              items={fermentation} 
              onClickEdit={onClickEdit}
              onDragStart={dragStart}/>
          </div>
          <i className={'addlegend' + (fermentation.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper id="egouttage" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInEgouttage')} 
          onDragOver={dragOver} 
          onDrop={dropEgouttage}
          aria-label="Ajouter une préparation">
          <GradientIcon />
          <Typography color="textSecondary" align="center"><strong>en Egouttage</strong></Typography>
          <div className="flexer flexer-egouttage">
            <ListItems
              items={egouttage} 
              onClickEdit={onClickEdit}
              onDragStart={dragStart}/>
          </div>
          <i className={'addlegend' + (egouttage.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper id="frigo" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInFrigo')} 
          onDragOver={dragOver} 
          onDrop={dropFrigo}
          aria-label="Ajouter une préparation">
          <KitchenIcon />
          <Typography color="textSecondary" align="center"><strong>au Frigo</strong></Typography>
          <div className="flexer flexer-frigo">
            <ListItems
              items={frigo} 
              onClickEdit={onClickEdit}
              onDragStart={dragStart}/>
          </div>
          <div className="legend">DLUO = frigo + 8 jours</div>
          <i className={'addlegend' + (frigo.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper id="decongel" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInDecongel')} 
          onDragOver={dragOver} 
          onDrop={dropDecongel}
          aria-label="Ajouter une préparation">
          <AcUnitIcon />
          <Typography color="textSecondary" align="center"><strong>en Décongélation</strong></Typography>
          <div className="flexer flexer-frigo">
            <ListItems
              items={decongel} 
              onClickEdit={onClickEdit}
              onDragStart={dragStart}/>
          </div>
          <div className="legend">DLUO = décongel + 2 jours</div>
          <i className={'addlegend' + (decongel.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={7}>
        <Paper id="congel" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInCongel')} 
          onDragOver={dragOver} 
          onDrop={dropCongel}
          aria-label="Ajouter une préparation">
          <AcUnitIcon />
          <Typography color="textSecondary" align="center"><strong>au Congèl</strong></Typography>
          <ListItems
            items={congel} 
            onClickEdit={onClickEdit}
            onDragStart={dragStart}/>
          <div className="legend">DLUO = congèl + 180 jours</div>
          <i className={'addlegend' + (congel.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper id="placard" elevation={0} className="fullDimension" 
          onClick={(e) => onClickCreate(e,'isInPlacard')} 
          onDragOver={dragOver} 
          onDrop={dropPlacard}
          aria-label="Ajouter une préparation">
          <Typography color="textSecondary" align="center"><strong>au Placard</strong></Typography>
          <ListItems
            items={placard} 
            onClickEdit={onClickEdit}
            onDragStart={dragStart}/>
          <i className={'addlegend' + (placard.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper id="fermentation2" elevation={0} className="fullDimension" 
          onDragOver={dragOver} 
          onDrop={dropFermentationScrollTop}>
          <Typography color="textSecondary" align="center"><strong>Mettre en Fermentation</strong></Typography>
          <i className={'addlegend' + (fermentation.length===0 ? ' centered' : '') + (dragging ? ' dragging' : '')} title="Ajouter une préparation"><ControlPointIcon /></i>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        
      </Grid>
    </Grid>
  );
}

export default Dashboard;
