import S_App    from   '../../services/S_App';

let DEFAULT_FERMENTS = ['Alsa','Yalacta','Skyr'];


let S_Ferments_Suggestions = {
  STORAGE_KEY_FERMENTS : 'ferments',

  ferments: [],      // liste des ferments en suggestion
  

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Ferments_Suggestions.tsx -- fetch()...');
    var ferments = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ferments_Suggestions.STORAGE_KEY_FERMENTS];
    if(ferments){
      S_Ferments_Suggestions.ferments = JSON.parse(ferments);
    }else{
      // datas de test
      S_Ferments_Suggestions.ferments = DEFAULT_FERMENTS;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Ferments_Suggestions.tsx -- fetch() ferments',S_Ferments_Suggestions.ferments);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ferments_Suggestions.STORAGE_KEY_FERMENTS] = JSON.stringify(S_Ferments_Suggestions.ferments);
    if(S_App.isLogDebug) console.log('-- S_Ferments_Suggestions.tsx -- store() ferments',S_Ferments_Suggestions.ferments);  
    if(cb)
      cb();
  },


  newFerment(name:string,cb){
    S_Ferments_Suggestions.ferments.push(name);
    S_Ferments_Suggestions.store(cb);
  },
  deleteFerment(name:string,cb){
    if(!S_Ferments_Suggestions.ferments || S_Ferments_Suggestions.ferments.length===0){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Ferments_Suggestions.ferments.length; i++){
      if(S_Ferments_Suggestions.ferments[i] === name){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Ferments_Suggestions.ferments.splice(foundDataIndex,1);
    }
    S_Ferments_Suggestions.store(cb);
    if(cb) cb();
  },
  updateFerment(value,ferment:string,cb){
    if(!S_Ferments_Suggestions.ferments || S_Ferments_Suggestions.ferments.length===0){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Ferments_Suggestions.ferments.length; i++){
      if(/*!!S_Ferments_Suggestions.ferments[i] && */S_Ferments_Suggestions.ferments[i] === ferment){
        S_Ferments_Suggestions.ferments[i] = value;
        break;
      }/*else{
        if(S_App.isLogDebug) console.log('-- S_Ferments_Suggestions.tsx -- updateFerment() ',value,ferment);  
      }*/
    }
    S_Ferments_Suggestions.store(() => {
      if(cb) cb(foundData);
    });
  },

};
export default S_Ferments_Suggestions;