import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import AcUnitIcon from '@material-ui/icons/AcUnit';

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import MilkType    from   '../elements/MilkType';
import FermentType  from   '../elements/FermentType';

// Styles
import './DataInEgouttage.scss';

type DataInEgouttageProps = {
  data: I_Data;
  onClickEdit?: any;  // func
  onDragStart?: any;  // func
}

function DataInEgouttage(props:DataInEgouttageProps) {

  // props
  let {data,onClickEdit,onDragStart} = props;

  // state

  // autres variables

  if(!data)
    return null;

  let limitDate = S_Datas.getLimitDate(data);
  let isExpired = S_Datas.isExpired(data);
  let lait = data ? S_Categories.getLait(data.baseLaitID) : undefined;

  let fermentName = data.baseName;
  if(data.fermentationIncrement>0){
    fermentName += ' ' +'('+data.fermentationIncrement+'x)';
  }
  
  return (
    <Paper className={'preparationInEgouttage' + (isExpired ? ' expired' : '') + (!!onClickEdit || !!onDragStart ? ' clickable' : '')}
      elevation={5}
      onClick={onClickEdit}
      draggable 
      onDragStart={onDragStart}>
      <div className="ingredients">
        <MilkType 
          baseLait={lait} 
          name={data.baseLaitName} 
          size="small"/>
        <span>+</span>
        <FermentType 
          type={S_Categories.getFormatFerment(data.fermentTypeID)} 
          size="small" 
          displayType={true} 
          name={fermentName} />
      </div>
      <div className="id">ID {data.id} ({data.idExtractedFrom}) : {data.fermentParentID}</div>
    </Paper>
  );
}

export default DataInEgouttage;
