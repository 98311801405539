import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WavesIcon from '@material-ui/icons/Waves';

// Styles
import './Recipe.scss';

type RecipeProps = {
  name?: string;
  subtitle?: string;
  quantity?: string,
  ingredients?: string[];      
  process?: any[];    // I_Recipe_Cook
  notes?: string; 
}

function Recipe(props:RecipeProps) {

  // props
  let {name,subtitle,quantity,ingredients,process,notes} = props;

  // state

  // autres variables

  function handleStopPropagation(e){
    e.stopPropagation();
  }

  return (
    <Accordion className="recipe" TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="">
        <div>
          <h3>{name}</h3>
          <div className="subtitle"><small>{subtitle}</small></div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex-1">
          <Grid className="" container spacing={0} justify="center">
            <Grid item xs={5}>
              <div className="mb-05">
                <div><strong>Quantité</strong></div>
                <div className="quantity">{quantity}</div>
              </div>
              <div>
                <div><strong>Ingrédients</strong></div>
                {ingredients.map((ingredient,index2) => {
                  return (
                    <div key={index2} className="ingredient">{ingredient}</div>
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={7}>
              <div className="text-center"><strong>Processus</strong></div>
              <div className="steps text-center" 
                onTouchStart={handleStopPropagation}
                onTouchMove={handleStopPropagation}
                onMouseDown={handleStopPropagation}
                onMouseMove={handleStopPropagation}>
                {process.map((step,index2) => {
                  const cookClass = (step.program==='yaourt' || step.program==='faisselle' || step.program==='dessert') ? 'yaourtiere' : (!step.program ? '' : 'other');
                  return (
                    <div key={index2} className="step">
                      <div className="stepCell">
                        {!!step.description ? <div className="description">{step.description}</div> : null}

                        <div className={'cook '+cookClass}>
                          {!!step.program ? <div className="cookLine">Pgm {step.program}</div> : null}
                          {!!step.duration ? <div className="cookLine">{step.duration}</div> : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
          <div><strong>Notes</strong></div>
          <div>{notes}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  // return (
  //   <Paper className="recipe p-1">
      

  //     <div className="header mb-05">
  //       <h3>{name}</h3>
  //       <div className="subtitle"><small>{subtitle}</small></div>
  //     </div>
  //     <div className="mb-05">
  //       <Grid className="" container spacing={0} justify="center">
  //         <Grid item xs={6}>
  //           <div className="mb-05">
  //             <div><strong>Quantité</strong></div>
  //             <div className="quantity">{quantity}</div>
  //           </div>
  //           <div>
  //             <div><strong>Ingrédients</strong></div>
  //             {ingredients.map((ingredient,index2) => {
  //               return (
  //                 <div key={index2} className="ingredient">{ingredient}</div>
  //               );
  //             })}
  //           </div>
  //         </Grid>
  //         <Grid item xs={6}>
  //           <div className="text-center"><strong>Processus</strong></div>
  //           <div className="steps text-center" 
  //             onTouchStart={handleStopPropagation}
  //             onTouchMove={handleStopPropagation}
  //             onMouseDown={handleStopPropagation}
  //             onMouseMove={handleStopPropagation}>
  //             {process.map((step,index2) => {
  //               return (
  //                 <div key={index2} className="step">
  //                   <div className="stepCell">
  //                     {!!step.description ? <div className="description">{step.description}</div> : null}
  //                     {!!step.program && !!step.duration ? (
  //                       <div className="cook yaourtiere">
  //                         <div className="cookLine">Mode {step.program}</div>
  //                         <div className="cookLine">{step.duration}</div>
  //                       </div>
  //                     ) : null}
  //                     {!!step.program && !step.duration ? (
  //                       <div className="cook">
  //                         <div className="cookLine">Mode {step.program}</div>
  //                       </div>
  //                     ) : null}
  //                     {!step.program && !!step.duration ? (
  //                       <div className="cook">
  //                         <div className="cookLine">{step.duration}</div>
  //                       </div>
  //                     ) : null}
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </div>
  //     <div>
  //       <div><strong>Notes</strong></div>
  //       <div>{notes}</div>
  //     </div>
  //   </Paper>
  // );
}

export default Recipe;
