import React from 'react';
// import PropTypes from 'prop-types';

// Composants génériques

// Composants Yaourts
import Note        from '../components/Note';
import {I_Note}    from '../services/I_Note';

// Styles
// import './Notes.scss';

type NotesProps = {
  notes: I_Note[];
  onClickRemove: any;
  onAskUpdate: any;
}

function Notes(props:NotesProps) {

  // props
  let {notes,onClickRemove,onAskUpdate} = props;

  // state

  // autres variables

  if(!notes)
    return null;

  return (
    <>
      { notes.map((note, index) => {
          if(!note)
            return null;

          // console.log(note);
          return (
            <Note key={index} 
              note={note}
              onClickRemove={onClickRemove}
              onAskUpdate={onAskUpdate} />
          );
        }
      ) }
    </>
  );
}

export default Notes;
