import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

// Composants génériques

// Composants Yaourts
import S_Datas         from '../services/S_Datas';
import S_Categories    from '../services/S_Categories';
import {I_Data}        from '../services/I_Data';

// Styles
// import './Appreciation.scss';

type DialogMoveToFermentationProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;

  data: I_Data;  
}

function DialogMoveToFermentation(props:DialogMoveToFermentationProps) {

  // props
  let {isDialogOpened,closeDialog,data,onClickValidate} = props;

  let defaultLait = data && !!data.baseLaitID ? data.baseLaitID : 1;
  let defaultMl = data && !!data.ml ? data.ml : 1000;

  // state
  const [baselait, setBaselait] = useState(defaultLait);
  const [ml, setMl] = useState(defaultMl);
  const [quantity, setQuantity] = useState(1);

  function changeBaselait(value){
    setBaselait(value);
  }
  function changeMl(value){
    setMl(value);
  }
  function changeQuantity(value){
    setQuantity(value);
  }

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Quel lait voulez-vous fermenter ?</DialogTitle>
      <DialogContent>
        
        <FormControl fullWidth size="small">
          <InputLabel id="label-baselait">Type de lait</InputLabel>
          <Select autoFocus labelId="label-baselait" labelWidth={80} name="baselait" 
            value={baselait}
            onChange={(e) => changeBaselait(e.target.value)}>
            { S_Categories.laits.map( (lait,index) => <MenuItem value={lait.id} key={index}>{ lait.name }</MenuItem> ) }
          </Select>
        </FormControl>
        <TextField label="Quantité de lait (ml)" size="small" fullWidth InputLabelProps={{shrink:true}} 
          value={ml}  
          onChange={(e) => changeMl(e.target.value)}/>

        {!S_Datas.isSingle(data) ? (
        <TextField id="quantity" label={'Quantité de ferment à utiliser (max '+data.quantity+')'} type="number" InputProps={{inputProps: { max: data.quantity, min: 1 }}} fullWidth size="small"
          value={quantity}
          onChange={(e) => changeQuantity(e.target.value)} />
        ):null}

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(baselait,ml,quantity)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogMoveToFermentation;
