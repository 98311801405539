import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

// Composants génériques

// Composants Yaourts
import {I_Data}       from   '../services/I_Data';

// Styles
import './Appreciation.scss';

type AppreciationProps = {
  data: I_Data;
  forceDisplay?: boolean;
}

function Appreciation(props:AppreciationProps) {

  // props
  let {data,forceDisplay} = props;

  if((!data || !data.appreciation) && !forceDisplay)
    return null;



  let count = !!data.appreciation ? data.appreciation : 0;
  let title = '';
  if(count>0) title = 'Jeté, beurk!';
  if(count>1) title = 'Mangeable';
  if(count>2) title = 'Neutre';
  if(count>3) title = 'Bon';
  if(count>4) title = 'Top!';

  return (
    <div className="appreciation" title={title}>
      { count>=1 ? <StarIcon /> : <StarBorderIcon /> }
      { count>=2 ? <StarIcon /> : <StarBorderIcon /> }
      { count>=3 ? <StarIcon /> : <StarBorderIcon /> }
      { count>=4 ? <StarIcon /> : <StarBorderIcon /> }
      { count>=5 ? <StarIcon /> : <StarBorderIcon /> }
    </div>
  );
}

export default Appreciation;
