// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';

// Composants génériques

// Composants Yaourts
import FermentType     from '../elements/FermentType';

// Styles
// import './ListLaits.scss';

type ListFermentsTypesProps = {
  fermentTypes: any[];
}

function ListFermentsTypes(props:ListFermentsTypesProps) {

  // props
  let {fermentTypes} = props;

  // state

  // autres variables


  return (
    <>
    <h2>Types de ferments (non configurables)</h2>
    <Grid className="list-ferments-types" container spacing={2} justify="center">
      {fermentTypes.map((ferment,index) => (
        <Grid item xs={3} key={index} className="text-center">
          <FermentType type={ferment} displayType={true}/>
        </Grid>
      ))}
    </Grid>
    </>
  );
}

export default ListFermentsTypes;
