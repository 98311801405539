// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Modal  from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App  from '../../services/S_App';

// Composants Yaourts
import S_Datas      from '../services/S_Datas';
import {I_Data}     from '../services/I_Data';
import ListItems        from   '../components/ListItems';
import ListItemsArchived   from   '../components/ListItemsArchived';

// Styles
import './Modal.scss';

type ModalDataDeletionProps = {
  data: I_Data;

  isModalOpened: boolean;
  closeModal: any;            // function close

  onConfirmDelete: any;        // function
}

function ModalDataDeletion(props:ModalDataDeletionProps) {

  // props
  let {data,isModalOpened,closeModal,onConfirmDelete} = props;

  // state

  // autres variables


  if(!data)
    return null;


  let datasOfBain_childs = S_Datas.sortByLastDate(S_Datas.getDatasOfBain_childs(data.idExtractedFrom)); 
  let datasOfBainNotArchived_childs = S_App.getByFilters(datasOfBain_childs,[{attribute:'archived',value:false}]);
  let datasOfBainArchived_childs = S_App.getByFilters(datasOfBain_childs,[{attribute:'archived',value:true}]);
  
  let decongelOfBainNotArchived_childs = [];
  let frigoOfBainNotArchived_childs = [];
  let egouttageOfBainNotArchived_childs = [];
  let fermentationOfBainNotArchived_childs = [];
  let congelOfBainNotArchived_childs = [];
  let placardOfBainNotArchived_childs = [];
  for(var i = 0; i < datasOfBainNotArchived_childs.length; i++){
    let dataOfBain = datasOfBainNotArchived_childs[i];
    let status = S_Datas.getStatus(dataOfBain);
    if(status === S_Datas.STATUS_CONGEL)
      congelOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_FRIGO)
      frigoOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_EGOUTTAGE)
      egouttageOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_FERMENTATION)
      fermentationOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_DECONGEL)
      decongelOfBainNotArchived_childs.push(dataOfBain);
    if(status === S_Datas.STATUS_PLACARD)
      placardOfBainNotArchived_childs.push(dataOfBain);
  }

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        <div className="text-center">
          <h2>Supprimer la préparation ?</h2>
          <br/>


          {datasOfBain_childs.length>0 ? (
          <>
          <h3>Attention cet item a une filiation. Le supprimer va casser la lecture de la filiation.</h3>
          <Accordion className="accordion-no-paper">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="stats-content"
              id="stats-header">
              <strong>Créés avec ce bain</strong>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-content text-center">
                {datasOfBainNotArchived_childs.length>0 ? (
                <div>
                  {fermentationOfBainNotArchived_childs.length>0 ? <div><strong>En fermentation</strong><ListItems items={fermentationOfBainNotArchived_childs}/></div> : null}
                  {egouttageOfBainNotArchived_childs.length>0 ? <div><strong>En égouttage</strong><ListItems items={egouttageOfBainNotArchived_childs}/></div> : null}
                  {frigoOfBainNotArchived_childs.length>0 ? <div><strong>Au frigo</strong><ListItems items={frigoOfBainNotArchived_childs}/></div> : null}
                  {decongelOfBainNotArchived_childs.length>0 ? <div><strong>En décongélation</strong><ListItems items={decongelOfBainNotArchived_childs}/></div> : null}
                  {congelOfBainNotArchived_childs.length>0 ? <div><strong>Au congèl</strong><ListItems items={congelOfBainNotArchived_childs}/></div> : null}
                  {placardOfBainNotArchived_childs.length>0 ? <div><strong>Au placard</strong><ListItems items={placardOfBainNotArchived_childs}/></div> : null}
                </div>
                ) : null}
                {datasOfBainArchived_childs.length>0 ? <ListItemsArchived datas={datasOfBainArchived_childs}/> : null}
              </div>
            </AccordionDetails>
          </Accordion>
          </>
          ):null}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6} className="text-center">
            <Button variant="outlined" className="wide" onClick={closeModal}>Non</Button>
          </Grid>
          <Grid item xs={6} className="text-center">
            <Button variant="contained" startIcon={<DeleteIcon />} className="wide" onClick={onConfirmDelete}>Oui</Button>
          </Grid>
        </Grid>

      </div>
    </Modal>
  );
}

export default ModalDataDeletion;
