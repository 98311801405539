import React, { useState, useEffect } from 'react';

// Composants Material UI
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import NotesIcon from '@material-ui/icons/Notes';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Snackbar from '@material-ui/core/Snackbar';
import MenuBookIcon from '@material-ui/icons/MenuBook';

// Composants generiques
import S_App        from './services/S_App';
import S_Date       from './services/S_Date';
// import Panels       from './components/Panels';
import { Panels }   from 'react-mui-pwa-tools';

// Composants Yaourts
import S_Datas          from './_yaourts/services/S_Datas';
import {I_Data}         from './_yaourts/services/I_Data';
import S_Recipes        from './_yaourts/services/S_Recipes';
import {I_Recipe}       from './_yaourts/services/I_Recipe';

import PanelNotes       from './_notes/components/PanelNotes';
import PanelConfig      from './_config/components/PanelConfig';
import PanelDashboard   from './_yaourts/panels/PanelDashboard';
import PanelArchives    from './_yaourts/panels/PanelArchives';
import PanelRecipes     from './_yaourts/panels/PanelRecipes';

import ModalDataEdition from './_yaourts/modals/ModalDataEdition';
import ModalDataDeletion from './_yaourts/modals/ModalDataDeletion';
import DialogMoveToFermentation from './_yaourts/dialogs/DialogMoveToFermentation';
import DialogMoveToEgouttage    from './_yaourts/dialogs/DialogMoveToEgouttage';
import DialogMoveToFrigo        from './_yaourts/dialogs/DialogMoveToFrigo';
import DialogMoveToCongel       from './_yaourts/dialogs/DialogMoveToCongel';
import DialogMoveToDecongel     from './_yaourts/dialogs/DialogMoveToDecongel';

// Styles
import './App.scss';

function App() {

  // state
  const [swipePanelAllowed, setSwipePanelAllowed] = useState(true);

  const [modalDataEditionOpened, setModalDataEditionOpened] = useState(false);
  const [modalDataDeletionOpened, setModalDataDeletionOpened] = useState(false);

  const [isDialogMoveToFermentationOpened, setIsDialogMoveToFermentationOpened] = useState(false);
  const [isDialogMoveToEgouttageOpened, setIsDialogMoveToEgouttageOpened] = useState(false);
  const [isDialogMoveToFrigoOpened, setIsDialogMoveToFrigoOpened] = useState(false);
  const [isDialogMoveToCongelOpened, setIsDialogMoveToCongelOpened] = useState(false);
  const [isDialogMoveToDecongelOpened, setIsDialogMoveToDecongelOpened] = useState(false);

  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [datas, setDatas] = useState([]);
  const [data, setData] = useState(undefined);

  const [recipes, setRecipes] = useState([]); 

  // chargement des donnees
  useEffect(() => {
    S_Datas.fetch(() => {
      setDatas([...S_Datas.datas]);
    });
    S_Recipes.fetch(() => {
      setRecipes([...S_Recipes.recipes]);
    });
  }, []);


  // ------------------MODALS ------------------------

  function closeModalDataEdition(){
    setModalDataEditionOpened(false);
  }
  function openModalDataEdition(data:I_Data){
    setData(data);
    setModalDataEditionOpened(true);
  }
  function closeModalDataDeletion(){
    setModalDataDeletionOpened(false);
  }
  function openModalDataDeletion(data:I_Data){
    setData(data);
    setModalDataDeletionOpened(true);
  }


  // ------------------- DATAS ---------------------------

  // cree une nouvelle preparation et ouvre son modal edition
  function createDataAndOpenEdition(e,status:string) {
    S_Datas.newData(status,(newOne) => {
      setDatas([...S_Datas.datas]);
      openModalDataEdition(newOne)
      openSnackbar('Information créée');
    });
  }

  // changement d'une valeur de champ d'un item
  function changeFieldDataFromEdition(value,fieldName,data){
    S_Datas.updateData(value,fieldName,data,(foundData) => {
      if(!foundData)
        return;
      setDatas([...S_Datas.datas]);
      setData(foundData);
    });
  }

  // confirmation de suppression de l'item en cours
  function confirmDeleteData(){
    closeModalDataEdition();
    closeModalDataDeletion();
    S_Datas.delete(data,() => {
      setDatas([...S_Datas.datas]);
      setData(undefined);
      openSnackbar('Information supprimée');
    });
  }


  // deplace un data dans le frigo
  function moveDataToFrigo(data:I_Data){
    // cas ou on ne fait rien
    if(!data || S_Datas.getStatus(data) === S_Datas.STATUS_FRIGO || S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL){
      if(S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
        openSnackbar('Le produit reste en catégorie "décongelation".');
      return;
    }

    // cas ou on force une decongel
    if(S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL){
      openSnackbar('Le produit doit d\'abord être décongelé. Le produit passe en catégorie "décongelation".');
      moveDataToDecongel(data);
      return;
    }

    setData(data);
    setIsDialogMoveToFrigoOpened(true);
  }
  function validateDialogMoveToFrigo(fermentationTime,egouttageTime,isFerment,category_formatID,tag,taste,newQuantity,quantity,quantityPetitsLaits){
    let quantityMax = data.quantity;
    let quantityToUse = quantity;
    if(S_Datas.isSingle(data) || quantityToUse>=quantityMax){
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(fermentationTime,'fermentationTimeInHours',data,() => {
        S_Datas.updateData(egouttageTime,'egouttageTimeInHours',data,() => {
          S_Datas.updateData(category_formatID,'category_formatID',data,() => {
            S_Datas.updateData(isFerment,'isFerment',data,() => {
              S_Datas.updateData(tag,'dataTypeTag',data,() => {
                S_Datas.updateData(taste,'taste',data,() => {
                  S_Datas.updateData(newQuantity,'quantity',data,() => {
                  S_Datas.updateData(newQuantity,'quantityCreated',data,() => {
                    S_Datas.updateData(editedDate,'frigoDate',data,() => {
                      openSnackbar('Produit placé au frigo');
                      setDatas([...S_Datas.datas]);
                      setData(undefined);
                      setIsDialogMoveToFrigoOpened(false);
                    });
                  });
                  });
                });
              });
            });
          });
        });
      });
    }else{
      // on cree une data dans egouttage
      S_Datas.copyData(data,quantityToUse,(newOne) => {
        // on enleve la quantite a l'origine
        let newQuantityForOriginal = quantityMax - quantityToUse;
        S_Datas.updateData(newQuantityForOriginal,'quantity',data,(originalData) => {
          // on update le nouveau
          let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
          S_Datas.updateData(fermentationTime,'fermentationTimeInHours',data,() => {
            S_Datas.updateData(egouttageTime,'egouttageTimeInHours',data,() => {
              S_Datas.updateData(category_formatID,'category_formatID',data,() => {
                S_Datas.updateData(isFerment,'isFerment',data,() => {
                  S_Datas.updateData(taste,'taste',data,() => {
                    S_Datas.updateData(newQuantity,'quantity',data,() => {
                    S_Datas.updateData(newQuantity,'quantityCreated',data,() => {
                      S_Datas.updateData(editedDate,'frigoDate',data,() => {
                        openSnackbar('Produit placé au frigo');
                        setDatas([...S_Datas.datas]);
                        setData(undefined);
                        setIsDialogMoveToFrigoOpened(false);
                      });
                    });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
    if(quantityPetitsLaits>0){
      S_Datas.newPetitLait(data,quantityPetitsLaits,() => {
        openSnackbar('Petit lait créé au frigo');
        setDatas([...S_Datas.datas]);
      });
    }
  }

  // deplace un data dans le congel
  function moveDataToCongel(data:I_Data){
    // cas ou on ne fait rien
    if(!data || S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL || S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL){
      if(S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
        openSnackbar('On ne recongèle pas un produit décongelé!');
      return;
    }
    if(S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION || S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE){
      openSnackbar('Passer d\'abord par le frigo svp.');
      return;
    }

    if(S_Datas.isSingle(data)){
      // on met a jour la date de congel
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(editedDate,'congelDate',data,() => {
        openSnackbar('Produit placé au congélateur');
        setDatas([...S_Datas.datas]);
      });
    }else{
      setData(data);
      setIsDialogMoveToCongelOpened(true);
    }
  }
  function validateDialogMoveToCongel(quantity){
    let quantityMax = data.quantity;
    let quantityToUse = quantity;
    if(S_Datas.isSingle(data) || quantityToUse>=quantityMax){
      // on met a jour la date de congel
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(editedDate,'congelDate',data,() => {
        openSnackbar('Produit placé au congélateur');
        setDatas([...S_Datas.datas]);
        setData(undefined);
        setIsDialogMoveToCongelOpened(false);
      });
    }else{
      // on cree une data dans congel
      S_Datas.copyData(data,quantityToUse,(newOne) => {
        // on enleve la quantite a l'origine
        let newQuantity = quantityMax - quantityToUse;
        S_Datas.updateData(newQuantity,'quantity',data,(originalData) => {
          // on update le nouveau
          let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
          S_Datas.updateData(editedDate,'congelDate',newOne,() => {
            openSnackbar('Produit placé au congélateur');
            setDatas([...S_Datas.datas]);
            setData(undefined);
            setIsDialogMoveToCongelOpened(false);
          });
        });
      });
    }
  }

  // deplace un data dans le decongel
  function moveDataToDecongel(data:I_Data){
    // cas ou on ne fait rien
    if(!data || S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
      return;

    if(S_Datas.getStatus(data) !== S_Datas.STATUS_CONGEL){
      openSnackbar('Mais pourquoi ?');
      return;
    }

    if(S_Datas.isSingle(data)){
      // on met a jour la date de decongel
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(editedDate,'decongelDate',data,(foundData) => {
        openSnackbar('Produit placé en décongélation');
        setDatas([...S_Datas.datas]);
      });
    }else{
      setData(data);
      setIsDialogMoveToDecongelOpened(true);
    }
  }
  function validateDialogMoveToDecongel(quantity){
    let quantityMax = data.quantity;
    let quantityToUse = quantity;
    if(S_Datas.isSingle(data) || quantityToUse>=quantityMax){
      // on met a jour la date de congel
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(editedDate,'decongelDate',data,() => {
        openSnackbar('Produit placé en décongélation');
        setDatas([...S_Datas.datas]);
        setData(undefined);
        setIsDialogMoveToDecongelOpened(false);
      });
    }else{
      // on cree une data dans congel
      S_Datas.copyData(data,quantityToUse,(newOne) => {
        // on enleve la quantite au data d'origine
        let newQuantity = quantityMax - quantityToUse;
        S_Datas.updateData(newQuantity,'quantity',data,(originalData) => {

          // on update le nouveau
          let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
          S_Datas.updateData(editedDate,'decongelDate',newOne,() => {
            openSnackbar('Produit placé en décongélation');
            setDatas([...S_Datas.datas]);
            setData(undefined);
            setIsDialogMoveToDecongelOpened(false);
          });
        });
      });
    }
  }

  // deplace un data dans le egouttage
  function moveDataToEgouttage(data:I_Data){
    // cas ou on ne fait rien
    if(!data || S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE
      || S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL){
      if(S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
        openSnackbar('L\'application ne gère pas ce processus. Le produit reste en catégorie "décongelation".');
      return;
    }

    // cas ou on force une decongel
    if(S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL){
      openSnackbar('Le produit doit d\'abord être décongelé. Le produit passe en catégorie "décongelation".');
      moveDataToDecongel(data);
      return;
    }

    setData(data);
    setIsDialogMoveToEgouttageOpened(true);
  }
  function validateDialogMoveToEgouttage(fermentationTime,quantity){
    let quantityMax = data.quantity;
    let quantityToUse = quantity;
    if(S_Datas.isSingle(data) || quantityToUse>=quantityMax){
      let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
      S_Datas.updateData(editedDate,'egouttageDate',data,() => {
        S_Datas.updateData(undefined,'frigoDate',data,() => {
          S_Datas.updateData(fermentationTime,'fermentationTimeInHours',data,() => {
            if(S_Datas.getStatus(data) === S_Datas.STATUS_FRIGO){
              openSnackbar('Produit placé en égouttage. Attention, la date de mise au frigo est réinitialisée.');
            }else{
              openSnackbar('Produit placé en égouttage');
            }
            setDatas([...S_Datas.datas]);
            setData(undefined);
            setIsDialogMoveToEgouttageOpened(false);
          });
        });
      });
    }else{
      // on cree une data dans egouttage
      S_Datas.copyData(data,quantityToUse,(newOne) => {
        // on enleve la quantite a l'origine
        let newQuantity = quantityMax - quantityToUse;
        S_Datas.updateData(newQuantity,'quantity',data,(originalData) => {
          // on update le nouveau
          let editedDate = S_Datas.formatDateForMaterial((new Date()).getTime());
          S_Datas.updateData(editedDate,'egouttageDate',newOne,() => {
            S_Datas.updateData(undefined,'frigoDate',newOne,() => {
              S_Datas.updateData(fermentationTime,'fermentationTimeInHours',newOne,() => {
                if(S_Datas.getStatus(data) === S_Datas.STATUS_FRIGO){
                  openSnackbar('Produit placé en égouttage. Attention, la date de mise au frigo est réinitialisée.');
                }else{
                  openSnackbar('Produit placé en égouttage');
                }
                setDatas([...S_Datas.datas]);
                setData(undefined);
                setIsDialogMoveToEgouttageOpened(false);
            });
            });
          });
        });
      });
    }
  }

  // deplace un data dans le fermentation
  function moveDataToFermentation(data:I_Data){
    // cas ou on ne fait rien
    if(!data || S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION)
      return;

    setData(data);
    setIsDialogMoveToFermentationOpened(true);
  }
  function validateDialogMoveToFermentation(baselait, ml, quantity){
    // on cree une data dans fermentation
    S_Datas.newDataFrom(data,baselait,ml,() => {
      /* if(scrollTop)*/ window.scrollTo({ top: 0, behavior: 'smooth' });
      let fermentQuantity = data.quantity;
      let fermentQuantityToUse = quantity;
      if(S_Datas.isSingle(data) || fermentQuantityToUse>=fermentQuantity){
        // on archive le ferment drope (ne pas supprimer sinon on perd la filiation)
        // S_Datas.delete(data,() => {
        //   openSnackbar('Produit placé en fermentation');
        //   setDatas([...S_Datas.datas]);
        //   setData(undefined);
        //   setIsDialogMoveToFermentationOpened(false);
        // });
        S_Datas.updateData(true,'archived',data,(foundData) => {
          if(!foundData)
            return;
          openSnackbar('Produit archivé');
          setDatas([...S_Datas.datas]);
          setData(undefined);
          setIsDialogMoveToFermentationOpened(false);
        });
      }else{
        // on enleve la quantite au ferment drope
        let newQuantity = fermentQuantity - fermentQuantityToUse;
        S_Datas.updateData(newQuantity,'quantity',data,(foundData) => {
          if(!foundData)
            return;
          openSnackbar('Produit utilisé');
          setDatas([...S_Datas.datas]);
          setData(undefined);
          setIsDialogMoveToFermentationOpened(false);
        });
      }
    });
  }


  function moveDataToPlacard(data:I_Data){
    openSnackbar('Les produits frais ne doivent pas être placés au placard.');
    return;
  }


  // gestion du swipe sur le dashboard pour desactiver le swipe panels
  function dragStart(){
    setSwipePanelAllowed(false);
  }
  function dragEnd(){
    setSwipePanelAllowed(true);
  }


  // -------------- SNACKBAR --------------------
  function openSnackbar(message) {
    setSnackbarOpened(true);
    setSnackbarMessage(message);
  }
  function closeSnackbar(){
    setSnackbarOpened(false);
    setSnackbarMessage('');
  }



  let datasArchived = S_App.getByFilters(datas,[{attribute:'archived',value:true}]);


  // configuration des panels
  let panelContents = [
    <PanelNotes />,
    <PanelRecipes
      recipes={recipes} />,
    <PanelDashboard
      datas={datas}
      onClickEditData={openModalDataEdition}
      onClickCreateData={createDataAndOpenEdition}
      onMoveDataToFermentation={moveDataToFermentation}
      onMoveDataToEgouttage={moveDataToEgouttage}
      onMoveDataToFrigo={moveDataToFrigo}
      onMoveDataToCongel={moveDataToCongel}
      onMoveDataToDecongel={moveDataToDecongel}
      onMoveDataToPlacard={moveDataToPlacard}
      onDragStart={dragStart}
      onDragEnd={dragEnd} />,
    <PanelArchives 
      datas={datasArchived}
      onClickEdit={openModalDataEdition} />,
    <PanelConfig />
  ];
  let tabs = [
    {
      label: 'Notes',
      icon: <NotesIcon />
    },
    {
      label: 'Recettes',
      icon: <MenuBookIcon />
    },
    {
      label: 'Dashboard',
      icon: <DashboardIcon />
    },
    {
      label: 'Stats',
      icon: <BarChartIcon />
    },
    {
      label: 'Config',
      icon: <MoreHorizOutlinedIcon /> 
    }
  ]; 



  return (
    <>
      <Panels 
        enabled={swipePanelAllowed}
        contents={panelContents}
        tabs={tabs}
        defaultPanelIndex={2}/>

      {modalDataEditionOpened ? (
      <ModalDataEdition 
        data={data}
        isModalOpened={modalDataEditionOpened}
        closeModal={closeModalDataEdition}
        onFieldChange={changeFieldDataFromEdition}
        onClickDelete={openModalDataDeletion}/>
      ) : null}
      {modalDataDeletionOpened ? (
      <ModalDataDeletion 
        data={data}
        isModalOpened={modalDataDeletionOpened}
        closeModal={closeModalDataDeletion}
        onConfirmDelete={confirmDeleteData}/>
      ) : null}
      {isDialogMoveToFermentationOpened ? (
      <DialogMoveToFermentation
        isDialogOpened={isDialogMoveToFermentationOpened}
        closeDialog={(e) => setIsDialogMoveToFermentationOpened(false)}
        onClickValidate={validateDialogMoveToFermentation}
        data={data}  />
      ) : null}
      {isDialogMoveToEgouttageOpened ? (
      <DialogMoveToEgouttage
        isDialogOpened={isDialogMoveToEgouttageOpened}
        closeDialog={(e) => setIsDialogMoveToEgouttageOpened(false)}
        onClickValidate={validateDialogMoveToEgouttage}
        data={data}  />
      ) : null}
      {isDialogMoveToFrigoOpened ? (
      <DialogMoveToFrigo
        isDialogOpened={isDialogMoveToFrigoOpened}
        closeDialog={(e) => setIsDialogMoveToFrigoOpened(false)}
        onClickValidate={validateDialogMoveToFrigo}
        data={data}  />
      ) : null}
      {isDialogMoveToCongelOpened ? (
      <DialogMoveToCongel
        isDialogOpened={isDialogMoveToCongelOpened}
        closeDialog={(e) => setIsDialogMoveToCongelOpened(false)}
        onClickValidate={validateDialogMoveToCongel}
        data={data}  />
      ) : null}
      {isDialogMoveToDecongelOpened ? (
      <DialogMoveToDecongel
        isDialogOpened={isDialogMoveToDecongelOpened}
        closeDialog={(e) => setIsDialogMoveToDecongelOpened(false)}
        onClickValidate={validateDialogMoveToDecongel}
        data={data}  />
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpened}
        autoHideDuration={1500}
        onClose={closeSnackbar}
        message={snackbarMessage} />
    </>
  );
}

export default App;
