import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Composants génériques

// Composants Yaourts
import {I_Note}      from '../services/I_Note';

// Styles
import './Note.scss';

type NoteProps = {
  note: I_Note;
  onClickRemove: any;
  onAskUpdate: any;
}

function Note(props:NoteProps) {

  // props
  let {note,onClickRemove,onAskUpdate} = props;

  // state
  const [editOn, setEditOn] = React.useState(false);

  // autres variables



  if(!note)
    return null;

  let dateFormatted = note.lastupdate ? (new Date(note.lastupdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
  
  return (
    <Paper elevation={1} className="paper-note">
      <TextField label={dateFormatted} variant="outlined" multiline rows="5" className="textfield"
        value={note.content} disabled={editOn===undefined || !editOn}
        onChange={(e) => onAskUpdate(note,e.target.value)}
        onBlur={(e) => setEditOn(false)}
        onClick={editOn===undefined || !editOn ? (e) => setEditOn(true) : undefined }/>
      { editOn ? <Fab color={'default'} size="small" className="remove-notes-btn abs-br" onClick={(e) => onClickRemove(note)}><DeleteForeverIcon /></Fab> : null }
    </Paper>
  );
}

export default Note;
