// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';

// Composants génériques

// Composants Yaourts
import MilkType        from '../elements/MilkType';

// Styles
// import './ListLaits.scss';

type ListLaitsProps = {
  laits: any[];
}

function ListLaits(props:ListLaitsProps) {

  // props
  let {laits} = props;

  // state

  // autres variables


  return (
    <>
    <h2>Types de laits (non configurables)</h2>
    <Grid className="list-laits" container spacing={2} justify="center">
      {laits.map((lait,index) => (
        <Grid item xs={3} key={index} className="text-center">
          <MilkType baseLait={lait} />
        </Grid>
      ))}
    </Grid>
    </>
  );
}

export default ListLaits;
