import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Paper from '@material-ui/core/Paper';
import AcUnitIcon from '@material-ui/icons/AcUnit';

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import DataFerment from   '../elements/DataFerment';
import MilkType    from   '../elements/MilkType';
// import CookType    from   '../elements/CookType';
import Appreciation from   '../elements/Appreciation';
import TasteType    from   '../elements/TasteType';

// Styles
import './DataPreparation.scss';

type DataPreparationProps = {
  data: I_Data;
  onClickEdit?: any;  // func
  onDragStart?: any;  // func
}

function DataPreparation(props:DataPreparationProps) {

  // props
  let {data,onClickEdit,onDragStart} = props;

  // state

  // autres variables

  if(!data)
    return null;

  let limitDate = S_Datas.getLimitDate(data);
  let isExpired = S_Datas.isExpired(data);
  let lait = data ? S_Categories.getLait(data.baseLaitID) : undefined;
  let format = data ? S_Categories.getFormat(data.category_formatID) : undefined;
  let isInCongel = S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL;

  let limitDateFormatted = '';
  if(limitDate){
    limitDateFormatted = limitDate.toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: 'short' });
    let todayAt0 = (new Date()).getTime();
    if(limitDate > todayAt0 && limitDate <= (todayAt0 + 24*60*60*1000)){
      limitDateFormatted = 'demain';
    }
    if(limitDate > todayAt0 && limitDate <= (todayAt0 + 24*60*60*1000*2)){
      limitDateFormatted = 'après-demain';
    }
    if(isInCongel){
      limitDateFormatted = limitDate ? limitDate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: undefined, weekday: undefined }) : null;
    }
  }

  let name = !!data.dataTypeTag ? data.dataTypeTag : (!!format ? format.name : '');


  let cssClass = format ? format.cssClass : '';

  return (
    <Paper className={'preparation' + (isExpired ? ' expired' : '') + ' ' + cssClass + (!!onClickEdit || !!onDragStart ? ' clickable' : '')}
      elevation={5}
      onClick={onClickEdit}
      draggable 
      onDragStart={onDragStart}>

      <div className="d-flex-v">
        <div className="name">{name}</div>
        { data.taste !== '' ? <div className="taste"><TasteType taste={data.taste} /></div> : null }
        { limitDateFormatted ? <div className="dluo"><small>DLUO</small> <span>{limitDateFormatted}</span></div> : null }

        <div className="ingredients">
          <MilkType baseLait={lait} />
          <span>+</span>
          <DataFerment data={data} />
        </div>

      </div>

      {data && data.quantity>1 ? <div className="quantity">{data.quantity}</div> : null}
      { data.congelDate ? <div className="freeze"><AcUnitIcon /></div> : null }

        <div className="id">ID {data.id} ({data.idExtractedFrom}) : {data.fermentParentID}</div>
    </Paper>
  );
}

export default DataPreparation;
