// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Grid from '@material-ui/core/Grid';

// Composants génériques

// Composants Yaourts
import S_Categories  from '../services/S_Categories';
import S_Datas     from '../services/S_Datas';
import {I_Data}    from '../services/I_Data';
import StatFormat          from   '../elements/StatFormat';

// Styles
// import './ListCountsByYears.scss';

type ListCountsByYearsProps = {
  datas: I_Data[];
}

function ListCountsByYears(props:ListCountsByYearsProps) {

  // props
  let {datas} = props;

  // state

  // autres variables

  // console.log(S_Datas.getYears(datas));
  let years = S_Datas.getYears(datas).reverse();
  let dataTypeYaourt = S_Categories.getFormat(20);
  let dataTypeDessert = S_Categories.getFormat(40);


  return (
    <>
    { years.map((year,index) => {
      let datasYear = S_Datas.getDatasOfYear(datas,year);
      let milkQuantity = S_Datas.getMilkQuantityOfDatas(datasYear);
      let milkQuantityLabel = !! milkQuantity ? ', ' + (parseInt(milkQuantity) / 1000) + 'L de lait' : null;
      let quantities = S_Datas.getQuantitiesOfDatas(datasYear);
      let quantitiesLabel = !! quantities ? ', ' + quantities + ' pot(s)' : null;
      return (
        <div key={index}>
          <h3>
            <strong>{year}</strong> : {datasYear.length} préparation(s)
            {milkQuantityLabel}{quantitiesLabel}
          </h3>
          <Grid container spacing={0} className="stats">
            { S_Categories.formats.map((format,index2) => {
              if(format.id===1 || format.id===2)      // pas besoin d'afficher petit lait et sachet, on l'archive jamais
                return;
              if(!!format.to_be_replaced_by)  // ces formats ont ete remplaces par des tags
                return;
              return (
                <Grid item xs={3} key={index2}>
                  <StatFormat count={S_Datas.getDatasOfFormat(datasYear,format.id).length} format={format} />
                </Grid>
                );
            }) }
          </Grid>
          <hr className="mv-05"/>
          <Grid container spacing={0} className="stats">
            { dataTypeYaourt.tags.map((tag,index2) => {
              let datasOfTag = S_Datas.getDatasOfFormatAndTag(datasYear,20,tag);
              if(!datasOfTag || datasOfTag.length===0)
                return;
              return (
                <Grid item xs={6} key={index2}>
                  <StatFormat count={datasOfTag.length} format={dataTypeYaourt} tag={tag}/>
                </Grid>
                );
            }) }
            { dataTypeDessert.tags.map((tag,index2) => {
              let datasOfTag = S_Datas.getDatasOfFormatAndTag(datasYear,40,tag);
              if(!datasOfTag || datasOfTag.length===0)
                return;
              return (
                <Grid item xs={6} key={index2}>
                  <StatFormat count={datasOfTag.length} format={dataTypeDessert} tag={tag}/>
                </Grid>
                );
            }) }
          </Grid>
        </div>
      );
    }) }
    </>
  );
}

export default ListCountsByYears;
