// Composant version 2021
// ----------------------------------

import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants Yaourts
import S_Datas            from   '../services/S_Datas';
import Preparation        from   '../elements/Preparation';
import DataFerment        from   '../elements/DataFerment';
import DataInFermentation from   '../elements/DataInFermentation';
import DataInEgouttage    from   '../elements/DataInEgouttage';
import DataPreparation    from   '../elements/DataPreparation';

// Styles
// import './ListItems.scss';

type ListItemsProps = {
  items: any[];
  onClickEdit?: any;
  onDragStart?: any;
}

function ListItems(props:ListItemsProps) {

  // props
  let {items,onClickEdit,onDragStart} = props;

  // state

  // autres variables


  // on affiche d'abord les preparations, puis les ferments
  return (
    <>
    <div className="list-items">
      { items.map((data,index) => {
        if(data.isFerment)
          return null;

        let statusCode = '';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_FRIGO)
          statusCode = 'egouttage';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL)
          statusCode = 'congel';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
          statusCode = 'decongel';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION)
          statusCode = 'fermentation';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE)
          statusCode = 'egouttage';

        if(S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION)
          return (
            <DataInFermentation key={index}
              data={data} 
              onClickEdit={(e) => onClickEdit(e,data)} 
              onDragStart={(e) => onDragStart(e,data,statusCode)}  />
          );
        if(S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE)
          return (
            <DataInEgouttage key={index}
              data={data} 
              onClickEdit={(e) => onClickEdit(e,data)} 
              onDragStart={(e) => onDragStart(e,data,statusCode)}  />
          );

        // format ferment
        if(data.category_formatID === 1 || data.category_formatID === 2)
          return (
            <DataFerment key={index}
              data={data} 
              asA={'preparation'}
              onClickEdit={(e) => onClickEdit(e,data)} 
              onDragStart={(e) => onDragStart(e,data,statusCode)}/>
          );

        return (
          <DataPreparation key={index}
            data={data} 
            onClickEdit={(e) => onClickEdit(e,data)} 
            onDragStart={(e) => onDragStart(e,data,statusCode)}/>
        );


        // return (
        //   <Preparation key={index}
        //     data={data} 
        //     onClickEdit={(e) => onClickEdit(e,data)} 
        //     onDragStart={(e) => onDragStart(e,data,statusCode)}  />
        // );
      }) }
      { items.map((data,index) => {
        if(!data.isFerment)
          return null;
        
        let statusCode = '';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_FRIGO)
          statusCode = 'egouttage';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_CONGEL)
          statusCode = 'congel';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_DECONGEL)
          statusCode = 'decongel';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_FERMENTATION)
          statusCode = 'fermentation';
        if(S_Datas.getStatus(data) === S_Datas.STATUS_EGOUTTAGE)
          statusCode = 'egouttage';

        return (
          <DataFerment key={index}
            data={data} 
            asA={'preparation'}
            onClickEdit={(e) => onClickEdit(e,data)} 
            onDragStart={(e) => onDragStart(e,data,statusCode)}/>
        );
      }) }
    </div>
    </>
  );
}

export default ListItems;
