export const C_LAITS = [
  {
    'id':1,
    'name':'Entier',
    'cssClass':'red'
  },
  {
    'id':2,
    'name':'Ecrémé',
    'cssClass':'green'
  },
  {
    'id':3,
    'name':'Demi Ecrémé',
    'cssClass':'blue'
  },
  {
    'id':4,
    'name':'Soja',
    'cssClass':'orange'
  }
];